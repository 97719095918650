/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import Supplier from './Supplier';
import Goods from './Goods';
import GoodsCat from './GoodsCat';
import SkuMemberPrice from './SkuMemberPrice';
import SkuDistributionPrice from './SkuDistributionPrice';
import Prop from './Prop';
import Value from './Value';
import GoodsTypeSet from './GoodsTypeSet';
import SkuFreightCost from './SkuFreightCost';
import GoodsMultiStyle from './GoodsMultiStyle';
import GoodsForbidSell from './GoodsForbidSell';

export default {
    Supplier,
    Goods,
    GoodsCat,
    SkuMemberPrice,
    SkuDistributionPrice,
    Prop,
    Value,
    GoodsTypeSet,
    SkuFreightCost,
    GoodsMultiStyle,
    GoodsForbidSell,
};
