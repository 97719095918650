/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';

class SkuDistributionPrice extends Gd {
    constructor() {
        super();
        this.baseUrl += '/skuDistributionPrice';
    }

    getSkuDistributionPriceList({ goodsId }) {
        return super.get('/getSkuDistributionPriceList', { goodsId });
    }

    saveSkuDistributionPrice({ jsonStr }) {
        return super.post('/saveSkuDistributionPrice', { jsonStr });
    }

    skuDistributionPriceList({
        goodsName,
        goodsSn,
        skuSn,
        goodsStatusType,
        supplierId,
        goodsSendModeList,
        currentPage,
        pageSize,
    }) {
        return super.get('/skuDistributionPriceList', {
            goodsName,
            goodsSn,
            skuSn,
            goodsStatusType,
            supplierId,
            goodsSendModeList,
            currentPage,
            pageSize,
        });
    }

    saveSkuDistributionPriceItem({ skuId, distributorLevelId, price }) {
        return super.post(
            '/saveSkuDistributionPriceItem',
            {
                skuId,
                distributorLevelId,
                price,
            },
            {
                isRepeat: true,
            },
        );
    }
}

export default new SkuDistributionPrice();
export { SkuDistributionPrice };
