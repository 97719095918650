/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class Order extends Ex {
    constructor() {
        super();
        this.baseUrl += '/order';
    }

    orderList(queryData) {
        let orderBeginTime = null;
        let orderEndTime = null;
        if (queryData.orderTime != null && queryData.orderTime.length === 2) {
            orderBeginTime = queryData.orderTime[0];
            orderEndTime = queryData.orderTime[1];
        }
        let presellEndTimeBegin = null;
        let presellEndTimeEnd = null;
        if (queryData.presellEndTime != null && queryData.presellEndTime.length === 2) {
            presellEndTimeBegin = queryData.presellEndTime[0];
            presellEndTimeEnd = queryData.presellEndTime[1];
        }
        let estimateShippingTimeBegin = null;
        let estimateShippingTimeEnd = null;
        if (queryData.estimateShippingTime != null && queryData.estimateShippingTime.length === 2) {
            estimateShippingTimeBegin = queryData.estimateShippingTime[0];
            estimateShippingTimeEnd = queryData.estimateShippingTime[1];
        }
        let confirmShipDateBegin = null;
        let confirmShipDateEnd = null;
        if (queryData.confirmShipDate != null && queryData.confirmShipDate.length === 2) {
            confirmShipDateBegin = queryData.confirmShipDate[0];
            confirmShipDateEnd = queryData.confirmShipDate[1];
        }
        return super.get('/orderList', {
            ...queryData,
            orderBeginTime: orderBeginTime,
            orderEndTime: orderEndTime,
            presellEndTimeBegin: presellEndTimeBegin,
            presellEndTimeEnd: presellEndTimeEnd,
            estimateShippingTimeBegin: estimateShippingTimeBegin,
            estimateShippingTimeEnd: estimateShippingTimeEnd,
            confirmShipDateBegin: confirmShipDateBegin,
            confirmShipDateEnd: confirmShipDateEnd,
        });
    }

    getEstimateShippingTimeList(queryData) {
        let orderBeginTime = null;
        let orderEndTime = null;
        if (queryData.orderTime != null && queryData.orderTime.length === 2) {
            orderBeginTime = queryData.orderTime[0];
            orderEndTime = queryData.orderTime[1];
        }
        let presellEndTimeBegin = null;
        let presellEndTimeEnd = null;
        if (queryData.presellEndTime != null && queryData.presellEndTime.length === 2) {
            presellEndTimeBegin = queryData.presellEndTime[0];
            presellEndTimeEnd = queryData.presellEndTime[1];
        }
        let estimateShippingTimeBegin = null;
        let estimateShippingTimeEnd = null;
        if (queryData.estimateShippingTime != null && queryData.estimateShippingTime.length === 2) {
            estimateShippingTimeBegin = queryData.estimateShippingTime[0];
            estimateShippingTimeEnd = queryData.estimateShippingTime[1];
        }
        let confirmShipDateBegin = null;
        let confirmShipDateEnd = null;
        if (queryData.confirmShipDate != null && queryData.confirmShipDate.length === 2) {
            confirmShipDateBegin = queryData.confirmShipDate[0];
            confirmShipDateEnd = queryData.confirmShipDate[1];
        }
        return super.get('/getEstimateShippingTimeList', {
            ...queryData,
            orderBeginTime: orderBeginTime,
            orderEndTime: orderEndTime,
            presellEndTimeBegin: presellEndTimeBegin,
            presellEndTimeEnd: presellEndTimeEnd,
            estimateShippingTimeBegin: estimateShippingTimeBegin,
            estimateShippingTimeEnd: estimateShippingTimeEnd,
            confirmShipDateBegin: confirmShipDateBegin,
            confirmShipDateEnd: confirmShipDateEnd,
        });
    }

    getOrderLodopPrintInfo(queryData) {
        let orderBeginTime = null;
        let orderEndTime = null;
        if (queryData.orderTime != null && queryData.orderTime.length === 2) {
            orderBeginTime = queryData.orderTime[0];
            orderEndTime = queryData.orderTime[1];
        }
        let presellEndTimeBegin = null;
        let presellEndTimeEnd = null;
        if (queryData.presellEndTime != null && queryData.presellEndTime.length === 2) {
            presellEndTimeBegin = queryData.presellEndTime[0];
            presellEndTimeEnd = queryData.presellEndTime[1];
        }
        let estimateShippingTimeBegin = null;
        let estimateShippingTimeEnd = null;
        if (queryData.estimateShippingTime != null && queryData.estimateShippingTime.length === 2) {
            estimateShippingTimeBegin = queryData.estimateShippingTime[0];
            estimateShippingTimeEnd = queryData.estimateShippingTime[1];
        }
        let confirmShipDateBegin = null;
        let confirmShipDateEnd = null;
        if (queryData.confirmShipDate != null && queryData.confirmShipDate.length === 2) {
            confirmShipDateBegin = queryData.confirmShipDate[0];
            confirmShipDateEnd = queryData.confirmShipDate[1];
        }
        return super.get('/getOrderLodopPrintInfo', {
            ...queryData,
            orderBeginTime: orderBeginTime,
            orderEndTime: orderEndTime,
            presellEndTimeBegin: presellEndTimeBegin,
            presellEndTimeEnd: presellEndTimeEnd,
            estimateShippingTimeBegin: estimateShippingTimeBegin,
            estimateShippingTimeEnd: estimateShippingTimeEnd,
            confirmShipDateBegin: confirmShipDateBegin,
            confirmShipDateEnd: confirmShipDateEnd,
        });
    }

    getOrderExpressId(queryData) {
        let orderBeginTime = null;
        let orderEndTime = null;
        if (queryData.orderTime != null && queryData.orderTime.length === 2) {
            orderBeginTime = queryData.orderTime[0];
            orderEndTime = queryData.orderTime[1];
        }
        let presellEndTimeBegin = null;
        let presellEndTimeEnd = null;
        if (queryData.presellEndTime != null && queryData.presellEndTime.length === 2) {
            presellEndTimeBegin = queryData.presellEndTime[0];
            presellEndTimeEnd = queryData.presellEndTime[1];
        }
        let estimateShippingTimeBegin = null;
        let estimateShippingTimeEnd = null;
        if (queryData.estimateShippingTime != null && queryData.estimateShippingTime.length === 2) {
            estimateShippingTimeBegin = queryData.estimateShippingTime[0];
            estimateShippingTimeEnd = queryData.estimateShippingTime[1];
        }
        let confirmShipDateBegin = null;
        let confirmShipDateEnd = null;
        if (queryData.confirmShipDate != null && queryData.confirmShipDate.length === 2) {
            confirmShipDateBegin = queryData.confirmShipDate[0];
            confirmShipDateEnd = queryData.confirmShipDate[1];
        }
        return super.get('/getOrderExpressId', {
            ...queryData,
            orderBeginTime: orderBeginTime,
            orderEndTime: orderEndTime,
            presellEndTimeBegin: presellEndTimeBegin,
            presellEndTimeEnd: presellEndTimeEnd,
            estimateShippingTimeBegin: estimateShippingTimeBegin,
            estimateShippingTimeEnd: estimateShippingTimeEnd,
            confirmShipDateBegin: confirmShipDateBegin,
            confirmShipDateEnd: confirmShipDateEnd,
        });
    }

    getDetail({ id }) {
        return super.get('/getDetail', { id });
    }

    cancelOrder({ id, cancelDesc }) {
        return super.post('/cancelOrder', { id, cancelDesc });
    }

    auditOrder({ ids, orderAuditStatus, auditOpinion }) {
        return super.post('/auditOrder', { ids, orderAuditStatus, auditOpinion });
    }

    completeOrder({ orderId }) {
        return super.post('/completeOrder', { orderId });
    }

    shippingOrder({ id, expressId, expressName, expressCode, trackingNumber }) {
        return super.post(
            '/shippingOrder',
            {
                id,
                expressId,
                expressName,
                expressCode,
                trackingNumber,
            },
            { timeout: 0 },
        );
    }

    batchShippingOrder({ ids, expressId }) {
        return super.post(
            '/batchShippingOrder',
            {
                ids,
                expressId,
            },
            {
                isHandleError: true,
                timeout: 0,
            },
        );
    }

    stockHandleOrder(params) {
        return super.post('/stockHandleOrder', params);
    }

    editOrderDeliveryInfo({
        id,
        deliveryConsignee,
        deliveryPhone,
        deliveryRegionId,
        deliveryAddress,
        deliveryIdCardNo,
    }) {
        return super.post('/editOrderDeliveryInfo', {
            id,
            deliveryConsignee,
            deliveryPhone,
            deliveryRegionId,
            deliveryAddress,
            deliveryIdCardNo,
        });
    }

    editOrderComments({ id, orderComments }) {
        return super.post('/editOrderComments', { id, orderComments });
    }

    batchSyncErpOrder({ ids }) {
        return super.post('/batchSyncErpOrder', { ids }, { timeout: 0, isHandleError: true });
    }

    pushOrderToGateway({ ids }) {
        return super.post('/pushOrderToGateway', { ids }, { timeout: 0, isHandleError: true });
    }

    batchSyncErpOrderTrackingInfo({ ids }) {
        return super.post('/batchSyncErpOrderTrackingInfo', { ids }, { timeout: 0, isHandleError: true });
    }

    batchSyncGatewayOrderTrackingInfo({ ids }) {
        return super.post('/batchSyncGatewayOrderTrackingInfo', { ids }, { timeout: 0, isHandleError: true });
    }

    csOrderList({
        orderSnEq,
        deliveryPhoneEq,
        orderStatus,
        shipMethod,
        goodsType,
        trackingNumberEq,
        buyerMobileEq,
        currentPage,
        pageSize,
    }) {
        return super.get('/csOrderList', {
            orderSnEq,
            deliveryPhoneEq,
            orderStatus,
            shipMethod,
            goodsType,
            trackingNumberEq,
            buyerMobileEq,
            currentPage,
            pageSize,
        });
    }

    csStoreOrderList({ orderSnEq, buyerMobileEq, currentPage, pageSize }) {
        return super.get('/csStoreOrderList', {
            orderSnEq,
            buyerMobileEq,
            currentPage,
            pageSize,
        });
    }

    checkIdCard({ id, deliveryConsignee, deliveryIdCardNo, deliveryIdCardFront, deliveryIdCardBack }) {
        let deliveryIdCardFrontStr = undefined;
        if (deliveryIdCardFront) {
            deliveryIdCardFrontStr = deliveryIdCardFront
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        let deliveryIdCardBackStr = undefined;
        if (deliveryIdCardBack) {
            deliveryIdCardBackStr = deliveryIdCardBack
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        return super.post('/checkIdCard', {
            id,
            deliveryConsignee,
            deliveryIdCardNo,
            deliveryIdCardFront: deliveryIdCardFrontStr,
            deliveryIdCardBack: deliveryIdCardBackStr,
        });
    }

    addOrderLog({ id, orderLog }) {
        return super.post('/addOrderLog', { id, orderLog });
    }

    saveOrderDriver({ ids, driverId }) {
        return super.post('/saveOrderDriver', { ids, driverId });
    }

    distributionOrder({ id }) {
        return super.post('/distributionOrder', { id });
    }

    completeDistributionOrder({ id, receiveRemarks, receiveAttachment }) {
        const receiveAttachmentStr = receiveAttachment
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/completeDistributionOrder', {
            id,
            receiveRemarks,
            receiveAttachment: receiveAttachmentStr,
        });
    }

    createOrder({
        buyerId,
        deliveryConsignee,
        deliveryPhone,
        deliveryRegionId,
        deliveryAddress,
        deliveryIdCardNo,
        subscriberName,
        subscriberIdCardNo,
        sendConsignor,
        sendTel,
        buyerMsg,
        goodsListJson,
    }) {
        return super.post('/createOrder', {
            buyerId,
            deliveryConsignee,
            deliveryPhone,
            deliveryRegionId,
            deliveryAddress,
            deliveryIdCardNo,
            subscriberName,
            subscriberIdCardNo,
            sendConsignor,
            sendTel,
            buyerMsg,
            goodsListJson,
        });
    }

    editSubscriber({ id, subscriberName, subscriberIdCardNo }) {
        return super.post('/editSubscriber', { id, subscriberName, subscriberIdCardNo });
    }

    saveExamineImage({ id, examineImage }) {
        const examineImageStr = examineImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/saveExamineImage', { id, examineImage: examineImageStr });
    }
}

export default new Order();
export { Order };
