/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class BuyerBill extends Mb {
    constructor() {
        super();
        this.baseUrl += '/buyerBill';
    }

    buyerBillWaitCreateList({
        nickName,
        mobile,
        buyerLevelId,
        distributorLevelId,
        comments,
        billType,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/buyerBillWaitCreateList', {
            nickName,
            mobile,
            buyerLevelId,
            distributorLevelId,
            comments,
            billType,
            currentPage,
            pageSize,
        });
    }

    buyerBillDetailList({ orderSn, orderTime, buyerId, billType, buyerBillId, currentPage, pageSize }) {
        let orderTimeBegin = null;
        let orderTimeEnd = null;
        if (orderTime != null && orderTime.length === 2) {
            orderTimeBegin = orderTime[0];
            orderTimeEnd = orderTime[1];
        }
        return super.get('/buyerBillDetailList', {
            orderSn,
            orderTimeBegin,
            orderTimeEnd,
            buyerId,
            billType,
            buyerBillId,
            currentPage,
            pageSize,
        });
    }

    createBuyerBill({ orderSn, orderTime, buyerId, billType, billName, billTime, remarks, ids }) {
        let orderTimeBegin = null;
        let orderTimeEnd = null;
        if (orderTime != null && orderTime.length === 2) {
            orderTimeBegin = orderTime[0];
            orderTimeEnd = orderTime[1];
        }
        let billTimeBegin = null;
        let billTimeEnd = null;
        if (billTime != null && billTime.length === 2) {
            billTimeBegin = billTime[0];
            billTimeEnd = billTime[1];
        }
        return super.post('/createBuyerBill', {
            orderSn,
            orderTimeBegin,
            orderTimeEnd,
            billTimeBegin,
            billTimeEnd,
            buyerId,
            billType,
            billName,
            remarks,
            ids,
        });
    }

    buyerBillList({
        nickName,
        mobile,
        buyerLevelId,
        distributorLevelId,
        billName,
        billTime,
        billType,
        isSettlement,
        remarks,
        currentPage,
        pageSize,
    }) {
        let billTimeBegin = null;
        let billTimeEnd = null;
        if (billTime != null && billTime.length === 2) {
            billTimeBegin = billTime[0];
            billTimeEnd = billTime[1];
        }
        return super.get('/buyerBillList', {
            nickName,
            mobile,
            buyerLevelId,
            distributorLevelId,
            billName,
            billTimeBegin,
            billTimeEnd,
            billType,
            isSettlement,
            remarks,
            currentPage,
            pageSize,
        });
    }

    delete({ id } = {}) {
        return super.post('/delete', {
            id,
        });
    }

    buyerBillDetail({ id }) {
        return super.get('/buyerBillDetail', { id });
    }

    createBuyerBillSettlement({ buyerBillId, settlementAmount, remarks, settlementAttachment }) {
        const settlementAttachmentStr = settlementAttachment
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/createBuyerBillSettlement', {
            buyerBillId,
            settlementAmount,
            remarks,
            settlementAttachment: settlementAttachmentStr,
        });
    }
}

export default new BuyerBill();
export { BuyerBill };
