/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Pm } from './Pm';

class CouponActivity extends Pm {
    constructor() {
        super();
        this.baseUrl += '/couponActivity';
    }

    data({ activityName, activityCode, currentPage, pageSize } = {}) {
        return super.get('/data', {
            activityName,
            activityCode,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, activityName, activityCode, activityDesc, distributorBuyerId } = {}) {
        return super.post('/save', {
            id,
            activityName,
            activityCode,
            activityDesc,
            distributorBuyerId,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    select() {
        return super.get('/select');
    }
}

export default new CouponActivity();
export { CouponActivity };
