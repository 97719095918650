/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ts } from './Ts';

class MarketCodeBatch extends Ts {
    constructor() {
        super();
        this.baseUrl += '/marketCodeBatch';
    }

    data({ batchName, batchSn, currentPage, pageSize } = {}) {
        return super.get('/data', {
            batchName,
            batchSn,
            currentPage,
            pageSize,
        });
    }

    createMarketCode({ id, batchName, batchSn, applyNum, remarks, codeSnPrefix, codeSnBegin } = {}) {
        return super.post('/createMarketCode', {
            id,
            batchName,
            batchSn,
            applyNum,
            remarks,
            codeSnPrefix,
            codeSnBegin,
        });
    }

    status({ id, isAvailable }) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post(
            '/delete',
            {
                ids,
            },
            { timeout: 0, isHandleError: true },
        );
    }
}

export default new MarketCodeBatch();
export { MarketCodeBatch };
