import { Pm } from './Pm';

class RaffleDetail extends Pm {
    constructor() {
        super();
        this.baseUrl += '/raffleDetail';
    }

    data({ raffleId, rafflePrizeId, rafflePrizeType, raffleCode, raffleDetailStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            raffleId,
            rafflePrizeId,
            rafflePrizeType,
            raffleCode,
            raffleDetailStatus,
            currentPage,
            pageSize,
        });
    }
    changeRafflePrize({ id, rafflePrizeId } = {}) {
        return super.post(
            '/changeRafflePrize',
            {
                id,
                rafflePrizeId,
            },
            { isHandleError: true },
        );
    }
    setComplete({ id, expressName, trackingNumber } = {}) {
        return super.post('/setComplete', {
            id,
            expressName,
            trackingNumber,
        });
    }
}
export default new RaffleDetail();
export { RaffleDetail };
