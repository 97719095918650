/**
 * Created by henian.xu on 2019/10/31.
 * 项目路由
 */
import { addPower, addRoutes, Layout } from 'vmf';
import demoRoutes from './demo';
import * as Api from '@/api';
import { ElementUI } from '@vmf/components';

const { Loading } = ElementUI;
const MenuProps = { data: {} }; // 菜单布局组件的 数据

// 复用页面
const rsSetUser = () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/user.vue');
const rsSetRole = () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/role.vue');
const rsSetOrg = () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/org.vue');

const pfspSpmgShopPaymentConfig = () =>
    import(/* webpackChunkName: "pfsp-spmg" */ '@/pages/pfsp/spmg/shopPaymentConfig.vue');

const shopStoreStoremgShopStoreEdit = () =>
    import(/* webpackChunkName: "shopStore-storemg" */ '@/pages/shopStore/storemg/shopStoreEdit.vue');
const shopStoreStoremgShopStoreServiceTime = () =>
    import(/* webpackChunkName: "shopStore-storemg" */ '@/pages/shopStore/storemg/shopStoreServiceTime.vue');
const shopStoreStoremgShopStoreFreight = () =>
    import(/* webpackChunkName: "shopStore-storemg" */ '@/pages/shopStore/storemg/shopStoreFreight.vue');
const orderOrderOrderDetail = () => import(/* webpackChunkName: "order-order" */ '@/pages/order/order/orderDetail.vue');

addRoutes(
    [
        {
            path: '/',
            component: Layout.Menu,
            props: MenuProps,
            children: [
                // ...OthersRoutes,
                ...demoRoutes,
                {
                    path: '/index',
                    name: 'index',
                    component: () => import('@/pages/index.vue'),
                    meta: {
                        title: '首页',
                    },
                },
                // ---平台路由
                ...[
                    // 系统管理
                    ...[
                        // 系统设置
                        ...[
                            {
                                path: '/rs/set/org',
                                name: 'rs-set-org',
                                component: rsSetOrg,
                                meta: {
                                    title: '部门管理',
                                },
                            },
                            {
                                path: '/rs/set/role',
                                name: 'rs-set-role',
                                component: rsSetRole,
                                meta: {
                                    title: '角色管理',
                                },
                            },
                            {
                                path: '/rs/set/variable',
                                name: 'rs-set-variable',
                                component: () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/variable.vue'),
                                meta: {
                                    title: '系统变量',
                                },
                            },
                            {
                                path: '/rs/set/region',
                                name: 'rs-set-region',
                                component: () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/region.vue'),
                                meta: {
                                    title: '区域管理',
                                },
                            },
                            {
                                path: '/rs/set/menu',
                                name: 'rs-set-menu',
                                component: () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/menu.vue'),
                                meta: {
                                    title: '菜单管理',
                                },
                            },
                            {
                                path: '/rs/set/user',
                                name: 'rs-set-user',
                                component: rsSetUser,
                                meta: {
                                    title: '用户管理',
                                },
                            },
                            {
                                path: '/rs/set/app',
                                name: 'rs-set-app',
                                component: () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/app.vue'),
                                meta: {
                                    title: '应用管理',
                                },
                            },
                            {
                                path: '/rs/set/dic',
                                name: 'rs-set-dic',
                                component: () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/dic.vue'),
                                meta: {
                                    title: '字典管理',
                                },
                            },
                            {
                                path: '/rs/set/express',
                                name: 'rs-set-express',
                                component: () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/express.vue'),
                                meta: {
                                    title: '快递公司管理',
                                },
                            },
                        ],
                    ],
                    // 店铺管理
                    ...[
                        // 店铺管理
                        ...[
                            {
                                path: '/pfsp/spmg/shopLevel',
                                name: 'pfsp-spmg-shopLevel',
                                component: () =>
                                    import(/* webpackChunkName: "pfsp-spmg" */ '@/pages/pfsp/spmg/shopLevel.vue'),
                                meta: {
                                    title: '店铺等级管理',
                                },
                            },
                            {
                                path: '/pfsp/spmg/shop',
                                name: 'pfsp-spmg-shop',
                                component: () =>
                                    import(/* webpackChunkName: "pfsp-spmg" */ '@/pages/pfsp/spmg/shop.vue'),
                                meta: {
                                    title: '店铺管理',
                                },
                            },
                            {
                                path: '/pfsp/spmg/shop/edit/:id?',
                                name: 'pfsp-spmg-shop-edit',
                                component: () =>
                                    import(/* webpackChunkName: "pfsp-spmg" */ '@/pages/pfsp/spmg/shopEdit.vue'),
                                meta: {
                                    title: '店铺管理',
                                    parentUrl: '/pfsp/spmg/shop',
                                },
                            },
                            {
                                path: '/pfsp/spmg/shop/shopBuyerAuthSet/:id',
                                name: 'pfsp-spmg-shop-shopBuyerAuthSet',
                                component: () =>
                                    import(/* webpackChunkName: "pfsp-spmg" */ '@/pages/pfsp/spmg/shopBuyerAuthSet.vue'),
                                meta: {
                                    title: '会员认证设置',
                                    parentUrl: '/pfsp/spmg/shop',
                                },
                            },
                            {
                                path: '/pfsp/spmg/shop/shopPaymentConfig/:id',
                                name: 'pfsp-spmg-shop-shopPaymentConfig',
                                component: pfspSpmgShopPaymentConfig,
                                meta: {
                                    title: '支付设置',
                                    parentUrl: '/pfsp/spmg/shop',
                                },
                            },
                        ],
                    ],
                    // 平台运营
                    ...[
                        // 平台设置
                        ...[
                            {
                                path: '/pfom/set/wxmsgOrg',
                                name: 'pfom-set-wxmsgOrg',
                                component: () =>
                                    import(/* webpackChunkName: "pfom-set" */ '@/pages/pfom/set/wxmsgOrg.vue'),
                                meta: {
                                    title: '微信消息模板',
                                },
                            },
                            {
                                path: '/pfom/set/wxmsgTemplate/:id?',
                                name: 'pfom-set-wxmsgTemplate',
                                component: () =>
                                    import(/* webpackChunkName: "pfom-set" */ '@/pages/pfom/set/wxmsgTemplate.vue'),
                                meta: {
                                    title: '微信消息模板',
                                    parentUrl: '/pfom/set/wxmsgOrg',
                                },
                            },
                            {
                                path: '/pfom/set/smsOrg',
                                name: 'pfom-set-smsOrg',
                                component: () =>
                                    import(/* webpackChunkName: "pfom-set" */ '@/pages/pfom/set/smsOrg.vue'),
                                meta: {
                                    title: '短信模板',
                                },
                            },
                            {
                                path: '/pfom/set/smsTemplate/:id?',
                                name: 'pfom-set-smsTemplate',
                                component: () =>
                                    import(/* webpackChunkName: "pfom-set" */ '@/pages/pfom/set/smsTemplate.vue'),
                                meta: {
                                    title: '短信模板',
                                    parentUrl: '/pfom/set/smsOrg',
                                },
                            },
                            {
                                path: '/pfom/set/orgCountryCode/:orgId',
                                name: 'pfom-set-orgCountryCode',
                                component: () =>
                                    import(/* webpackChunkName: "pfom-set" */ '@/pages/pfom/set/orgCountryCode.vue'),
                                meta: {
                                    title: '短信区域设置',
                                    parentUrl: '/pfom/set/smsOrg',
                                },
                            },
                            {
                                path: '/pfom/set/smsAccount',
                                name: 'pfom-set-smsAccount',
                                component: () =>
                                    import(/* webpackChunkName: "pfom-set" */ '@/pages/pfom/set/smsAccount.vue'),
                                meta: {
                                    title: '短信设置',
                                },
                            },
                        ],
                    ],
                ],
                // ---商家路由
                ...[
                    // 店铺管理
                    ...[
                        // 权限管理
                        ...[
                            {
                                path: '/sp/set/org',
                                name: 'sp-set-org',
                                component: rsSetOrg,
                                meta: {
                                    title: '部门管理',
                                },
                            },
                            {
                                path: '/sp/set/role',
                                name: 'sp-set-role',
                                component: rsSetRole,
                                meta: {
                                    title: '角色管理',
                                },
                            },
                            {
                                path: '/sp/set/user', // 复用 rs-set-user .vue
                                name: 'sp-set-user',
                                component: rsSetUser,
                                meta: {
                                    title: '用户管理',
                                },
                            },
                        ],
                        // 供应商管理
                        ...[
                            {
                                path: '/sp/supplier/supplier',
                                name: 'sp-supplier-supplier',
                                component: () =>
                                    import(/* webpackChunkName: "sp-supplier" */ '@/pages/sp/supplier/supplier.vue'),
                                meta: {
                                    title: '供应商管理',
                                },
                            },
                            {
                                path: '/sp/supplier/supplier/edit/:id?',
                                name: 'sp-supplier-supplierEdit',
                                component: () =>
                                    import(/* webpackChunkName: "sp-supplier" */ '@/pages/sp/supplier/supplierEdit.vue'),
                                meta: {
                                    title: '供应商管理',
                                    parentUrl: '/sp/supplier/supplier',
                                },
                            },
                            {
                                path: '/sp/supplier/supplier/goodsSaleStat',
                                name: 'sp-supplier-goodsSaleStat',
                                component: () =>
                                    import(/* webpackChunkName: "sp-supplier" */ '@/pages/stat/goodsStat/goodsSaleStat.vue'),
                                meta: {
                                    title: '供应商销量统计',
                                    parentUrl: '/sp/supplier/supplier',
                                },
                            },
                        ],
                        // 店铺管理
                        ...[
                            {
                                path: '/sp/spmg/shopInfo',
                                name: 'sp-spmg-shopInfo',
                                component: () =>
                                    import(/* webpackChunkName: "sp-spmg" */ '@/pages/sp/spmg/shopInfo.vue'),
                                meta: {
                                    title: '店铺信息',
                                },
                            },
                            {
                                path: '/sp/spmg/shopPaymentConfig',
                                name: 'sp-spmg-shopPaymentConfig',
                                component: pfspSpmgShopPaymentConfig,
                                meta: {
                                    title: '支付设置',
                                },
                            },
                            {
                                path: '/sp/spmg/shopFreightTemplate',
                                name: 'sp-spmg-shopFreightTemplate',
                                component: () =>
                                    import(/* webpackChunkName: "sp-spmg" */ '@/pages/sp/spmg/shopFreightTemplate.vue'),
                                meta: {
                                    title: '运费模板管理',
                                },
                            },
                            {
                                path: '/sp/spmg/shopFreightTemplate/edit/:id?',
                                name: 'sp-spmg-shopFreightTemplateEdit',
                                component: () =>
                                    import(/* webpackChunkName: "sp-spmg" */ '@/pages/sp/spmg/shopFreightTemplateEdit.vue'),
                                meta: {
                                    title: '运费模板管理',
                                    parentUrl: '/sp/spmg/shopFreightTemplate',
                                },
                            },
                            {
                                path: '/sp/spmg/shopFreightTemplate/chargeDescEdit/:id?',
                                name: 'sp-spmg-chargeDescEdit',
                                component: () =>
                                    import(/* webpackChunkName: "sp-spmg" */ '@/pages/sp/spmg/chargeDescEdit.vue'),
                                meta: {
                                    title: '运费规则装修',
                                    parentUrl: '/sp/spmg/shopFreightTemplate',
                                },
                            },
                            {
                                path: '/sp/spmg/shopFixedFreight',
                                name: 'sp-spmg-shopFixedFreight',
                                component: () =>
                                    import(/* webpackChunkName: "sp-spmg" */ '@/pages/sp/spmg/shopFixedFreight.vue'),
                                meta: {
                                    title: '固定运费管理',
                                },
                            },
                            {
                                path: '/sp/spmg/shopExpress',
                                name: 'sp-spmg-shopExpress',
                                component: () =>
                                    import(/* webpackChunkName: "sp-spmg" */ '@/pages/sp/spmg/shopExpress.vue'),
                                meta: {
                                    title: '快递公司管理',
                                },
                            },
                            {
                                path: '/sp/spmg/shopAftersaleTemplate',
                                name: 'sp-spmg-shopAftersaleTemplate',
                                component: () =>
                                    import(/* webpackChunkName: "sp-spmg" */ '@/pages/sp/spmg/shopAftersaleTemplate.vue'),
                                meta: {
                                    title: '售后模板管理',
                                },
                            },
                            {
                                path: '/sp/spmg/shopAftersaleTemplate/edit/:id?',
                                name: 'sp-spmg-shopAftersaleTemplate-edit',
                                component: () =>
                                    import(/* webpackChunkName: "sp-decoration" */ '@/pages/sp/spmg/shopAftersaleTemplateEdit.vue'),
                                meta: {
                                    title: '售后模板管理',
                                    parentUrl: '/sp/spmg/shopAftersaleTemplate',
                                },
                            },
                            {
                                path: '/sp/spmg/shopGoodsStyle',
                                name: 'sp-spmg-shopGoodsStyle',
                                component: () =>
                                    import(/* webpackChunkName: "sp-spmg" */ '@/pages/sp/spmg/shopGoodsStyle.vue'),
                                meta: {
                                    title: '商品风格管理',
                                },
                            },
                            {
                                path: '/sp/spmg/shopPremiumTemplate',
                                name: 'sp-spmg-shopPremiumTemplate',
                                component: () =>
                                    import(/* webpackChunkName: "sp-spmg" */ '@/pages/sp/spmg/shopPremiumTemplate.vue'),
                                meta: {
                                    title: '保费模板管理',
                                },
                            },
                            {
                                path: '/sp/spmg/shopOtoldTemplate',
                                name: 'sp-spmg-shopOtoldTemplate',
                                component: () =>
                                    import(/* webpackChunkName: "sp-spmg" */ '@/pages/sp/spmg/shopOtoldTemplate.vue'),
                                meta: {
                                    title: '本地配送模板',
                                },
                            },
                            {
                                path: '/sp/spmg/shopOtoldTemplate/edit/:id?',
                                name: 'sp-spmg-shopOtoldTemplateEdit',
                                component: () =>
                                    import(/* webpackChunkName: "sp-spmg" */ '@/pages/sp/spmg/shopOtoldTemplateEdit.vue'),
                                meta: {
                                    title: '本地配送模板',
                                    parentUrl: '/sp/spmg/shopOtoldTemplate',
                                },
                            },
                            {
                                path: '/sp/spmg/shopDriver',
                                name: 'sp-spmg-shopDriver',
                                component: () =>
                                    import(/* webpackChunkName: "sp-spmg" */ '@/pages/sp/spmg/shopDriver.vue'),
                                meta: {
                                    title: '配送员管理',
                                },
                            },
                        ],
                        // CMS管理
                        ...[
                            // CMS分类
                            ...[
                                {
                                    path: '/pt/cms/cmsCatalog',
                                    name: 'pt-cms-cmsCatalog',
                                    component: () =>
                                        import(/* webpackChunkName: "pt-cms" */ '@/pages/pt/cms/cmsCatalog'),
                                    meta: {
                                        title: 'CMS分类',
                                    },
                                },
                            ],
                            // CMS文章
                            ...[
                                {
                                    path: '/pt/cms/cmsArticle',
                                    name: 'pt-cms-cmsArticle',
                                    component: () =>
                                        import(/* webpackChunkName: "pt-cms" */ '@/pages/pt/cms/cmsArticle'),
                                    meta: {
                                        title: 'CMS文章',
                                    },
                                },
                                {
                                    path: '/pt/cms/cmsArticle/edit/:id?',
                                    name: 'pt-cms-cmsArticle-edit',
                                    component: () =>
                                        import(/* webpackChunkName: "pt-cms" */ '@/pages/pt/cms/cmsArticleEdit.vue'),
                                    meta: {
                                        title: 'CMS文章',
                                        parentUrl: '/pt/cms/cmsArticle',
                                    },
                                },
                            ],
                        ],
                    ],
                    // 门店管理
                    ...[
                        // 门店管理
                        ...[
                            {
                                path: '/shopStore/storemg/shopStore',
                                name: 'shopStore-storemg-shopStore',
                                component: () =>
                                    import(/* webpackChunkName: "shopStore-storemg" */ '@/pages/shopStore/storemg/shopStore.vue'),
                                meta: {
                                    title: '门店管理',
                                },
                            },
                            {
                                path: '/shopStore/storemg/shopStore/edit/:id?',
                                name: 'shopStore-storemg-shopStoreEdit',
                                component: shopStoreStoremgShopStoreEdit,
                                meta: {
                                    title: '门店管理',
                                    parentUrl: '/shopStore/storemg/shopStore',
                                },
                            },
                            {
                                path: '/shopStore/storemg/shopStore/shopStoreServiceTime/:id',
                                name: 'shopStore-storemg-shopStoreServiceTime',
                                component: shopStoreStoremgShopStoreServiceTime,
                                meta: {
                                    title: '服务时间设置',
                                    parentUrl: '/shopStore/storemg/shopStore',
                                },
                            },
                            {
                                path: '/shopStore/storemg/shopStore/shopStoreFreight/:id',
                                name: 'shopStore-storemg-shopStoreFreight',
                                component: shopStoreStoremgShopStoreFreight,
                                meta: {
                                    title: '运费设置',
                                    parentUrl: '/shopStore/storemg/shopStore',
                                },
                            },
                            {
                                path: '/shopStore/storemg/distributorShopStore',
                                name: 'shopStore-storemg-distributorShopStore',
                                component: () =>
                                    import(/* webpackChunkName: "shopStore-storemg" */ '@/pages/shopStore/storemg/distributorShopStore.vue'),
                                meta: {
                                    title: '分销商店铺管理',
                                },
                            },
                        ],
                    ],
                    // 商品管理
                    ...[
                        // 商品管理
                        ...[
                            {
                                path: '/gd/goods/goods',
                                name: 'gd-goods-goods',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/goods.vue'),
                                meta: {
                                    title: '商品管理',
                                    keepOnly: true,
                                },
                            },
                            {
                                path: '/gd/goods/addGoods/:goodsId?',
                                name: 'gd-goods-addGoods',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/addGoods.vue'),
                                meta: {
                                    title: '添加商品',
                                    parentUrl: '/gd/goods/goods',
                                    keepOnly: true,
                                },
                            },
                            {
                                path: '/gd/goods/bondedGoods',
                                name: 'gd-goods-bondedGoods',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/goods.vue'),
                                meta: {
                                    title: '保税商品管理',
                                    keepOnly: true,
                                },
                            },
                            {
                                path: '/gd/goods/addBondedGoods/:goodsId?',
                                name: 'gd-goods-addBondedGoods',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/addGoods.vue'),
                                meta: {
                                    title: '添加保税商品',
                                    parentUrl: '/gd/goods/bondedGoods',
                                    keepOnly: true,
                                },
                            },
                            {
                                path: '/gd/goods/goods/skuMemberPrice/:id',
                                name: 'gd-goods-goods-skuMemberPrice',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/skuMemberPrice.vue'),
                                meta: {
                                    title: '会员价',
                                    parentUrl: '/gd/goods/goods',
                                },
                            },
                            {
                                path: '/gd/goods/goods/skuDistributionPrice/:id',
                                name: 'gd-goods-goods-skuDistributionPrice',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/skuDistributionPrice.vue'),
                                meta: {
                                    title: '分销价',
                                    parentUrl: '/gd/goods/goods',
                                },
                            },
                            {
                                path: '/gd/goods/skuCost',
                                name: 'gd-goods-skuCost',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/skuCost.vue'),
                                meta: {
                                    title: '成本管理',
                                },
                            },
                            {
                                path: '/gd/goods/skuPrice',
                                name: 'gd-goods-skuPrice',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/skuPrice.vue'),
                                meta: {
                                    title: '商品价格',
                                },
                            },
                            {
                                path: '/gd/goods/distributorPrice',
                                name: 'gd-goods-distributorPrice',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/distributorPrice.vue'),
                                meta: {
                                    title: '分销价',
                                    keepOnly: true,
                                },
                            },
                            {
                                path: '/gd/goods/buyerPrice',
                                name: 'gd-goods-buyerPrice',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/buyerPrice.vue'),
                                meta: {
                                    title: '会员价',
                                    keepOnly: true,
                                },
                            },
                            {
                                path: '/gd/goods/thirdPlatformSku',
                                name: 'gd-goods-thirdPlatformSku',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/thirdPlatformSku.vue'),
                                meta: {
                                    title: '第三方平台sku',
                                },
                            },
                            {
                                path: '/gd/goods/goodsBaseSales',
                                name: 'gd-goods-goodsBaseSales',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/goodsBaseSales.vue'),
                                meta: {
                                    title: '基础销量设置',
                                },
                            },
                            {
                                path: '/gd/goods/bondedDistributorPrice',
                                name: 'gd-goods-bondedDistributorPrice',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/distributorPrice.vue'),
                                meta: {
                                    title: '保税分销价',
                                    keepOnly: true,
                                },
                            },
                            {
                                path: '/gd/goods/bondedBuyerPrice',
                                name: 'gd-goods-bondedBuyerPrice',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/buyerPrice.vue'),
                                meta: {
                                    title: '保税会员价',
                                    keepOnly: true,
                                },
                            },
                        ],
                        // 类目管理
                        ...[
                            {
                                path: '/gd/catmg/goodsCat',
                                name: 'gd-catmg-goodsCat',
                                component: () =>
                                    import(/* webpackChunkName: "gd-catmg" */ '@/pages/gd/catmg/goodsCat.vue'),
                                meta: {
                                    title: '后台分类管理',
                                },
                            },
                            {
                                path: '/gd/catmg/shopGoodsCat',
                                name: 'gd-catmg-shopGoodsCat',
                                component: () =>
                                    import(/* webpackChunkName: "gd-catmg" */ '@/pages/gd/catmg/shopGoodsCat.vue'),
                                meta: {
                                    title: '店铺分类管理',
                                },
                            },
                            {
                                path: '/gd/catmg/storeInitGoodsCat',
                                name: 'gd-catmg-storeInitGoodsCat',
                                component: () =>
                                    import(/* webpackChunkName: "gd-catmg" */ '@/pages/gd/catmg/storeInitGoodsCat.vue'),
                                meta: {
                                    title: '门店初始商品类目',
                                },
                            },
                        ],
                        // 活动管理
                        ...[
                            {
                                path: '/gd/activity/activity',
                                name: 'gd-activity-activity',
                                component: () =>
                                    import(/* webpackChunkName: "gd-activity" */ '@/pages/gd/activity/activity.vue'),
                                meta: {
                                    title: '活动列表',
                                },
                            },
                            {
                                path: '/gd/activity/activity/edit/:id?',
                                name: 'gd-activity-activity-edit',
                                component: () =>
                                    import(/* webpackChunkName: "gd-activity" */ '@/pages/gd/activity/activityEdit.vue'),
                                meta: {
                                    title: '活动管理',
                                    parentUrl: '/gd/activity/activity',
                                },
                            },
                            {
                                path: '/gd/activity/activityGoodsStock',
                                name: 'gd-activity-activityGoodsStock',
                                component: () =>
                                    import(/* webpackChunkName: "gd-activity" */ '@/pages/gd/activity/activityGoodsStock.vue'),
                                meta: {
                                    title: '活动库存',
                                },
                            },
                            {
                                path: '/gd/activity/activity/goodsSaleStat',
                                name: 'gd-activity-goodsSaleStat',
                                component: () =>
                                    import(/* webpackChunkName: "gd-activity" */ '@/pages/stat/goodsStat/goodsSaleStat.vue'),
                                meta: {
                                    title: '活动销量统计',
                                    parentUrl: '/gd/activity/activity',
                                },
                            },
                        ],
                        //满额活动
                        ...[
                            {
                                path: '/gd/fulfilGive/fulfilGiveFreeFreight',
                                name: 'gd-fulfilGive-fulfilGiveFreeFreight',
                                component: () =>
                                    import(/* webpackChunkName: "gd-fulfilGive" */ '@/pages/gd/fulfilGive/fulfilGiveFreeFreight.vue'),
                                meta: {
                                    title: '满额免邮',
                                },
                            },
                            {
                                path: '/gd/fulfilGive/fulfilGiveFreeFreight/edit/:id?',
                                name: 'gd-fulfilGive-fulfilGiveFreeFreight-edit',
                                component: () =>
                                    import(/* webpackChunkName: "gd-fulfilGive" */ '@/pages/gd/fulfilGive/fulfilGiveFreeFreightEdit.vue'),
                                meta: {
                                    title: '满额免邮',
                                    parentUrl: '/gd/fulfilGive/fulfilGiveFreeFreight',
                                },
                            },
                            {
                                path: '/gd/fulfilGive/fulfilGiveFullReduction',
                                name: 'gd-fulfilGive-fulfilGiveFullReduction',
                                component: () =>
                                    import(/* webpackChunkName: "gd-fulfilGive" */ '@/pages/gd/fulfilGive/fulfilGiveFullReduction.vue'),
                                meta: {
                                    title: '满额减免',
                                },
                            },
                            {
                                path: '/gd/fulfilGive/fulfilGiveFullReduction/edit/:id?',
                                name: 'gd-fulfilGive-fulfilGiveFullReduction-edit',
                                component: () =>
                                    import(/* webpackChunkName: "gd-fulfilGive" */ '@/pages/gd/fulfilGive/fulfilGiveFullReductionEdit.vue'),
                                meta: {
                                    title: '满额减免',
                                    parentUrl: '/gd/fulfilGive/fulfilGiveFullReduction',
                                },
                            },
                            {
                                path: '/gd/fulfilGive/fulfilGiveFullGive',
                                name: 'gd-fulfilGive-fulfilGiveFullGive',
                                component: () =>
                                    import(/* webpackChunkName: "gd-fulfilGive" */ '@/pages/gd/fulfilGive/fulfilGiveFullGive.vue'),
                                meta: {
                                    title: '满额赠送',
                                },
                            },
                            {
                                path: '/gd/fulfilGive/fulfilGiveFullGive/edit/:id?',
                                name: 'gd-fulfilGive-fulfilGiveFullGive-edit',
                                component: () =>
                                    import(/* webpackChunkName: "gd-fulfilGive" */ '@/pages/gd/fulfilGive/fulfilGiveFullGiveEdit.vue'),
                                meta: {
                                    title: '满额赠送',
                                    parentUrl: '/gd/fulfilGive/fulfilGiveFullGive',
                                },
                            },
                        ],
                    ],
                    // 会员管理
                    ...[
                        // 会员管理
                        ...[
                            {
                                path: '/mb/mbmg/buyerLevel',
                                name: 'mb-mbmg-buyerLevel',
                                component: () =>
                                    import(/* webpackChunkName: "mb-mbmg" */ '@/pages/mb/mbmg/buyerLevel.vue'),
                                meta: {
                                    title: '会员等级',
                                },
                            },
                            {
                                path: '/mb/mbmg/buyer',
                                name: 'mb-mbmg-buyer',
                                component: () => import(/* webpackChunkName: "mb-mbmg" */ '@/pages/mb/mbmg/buyer.vue'),
                                meta: {
                                    title: '会员列表',
                                },
                            },
                            {
                                path: '/mb/mbmg/wxuser',
                                name: 'mb-mbmg-wxuser',
                                component: () => import(/* webpackChunkName: "mb-mbmg" */ '@/pages/mb/mbmg/wxuser.vue'),
                                meta: {
                                    title: '微信用户列表',
                                },
                            },
                            {
                                path: '/mb/mbmg/mpuser',
                                name: 'mb-mbmg-mpuser',
                                component: () => import(/* webpackChunkName: "mb-mbmg" */ '@/pages/mb/mbmg/mpuser.vue'),
                                meta: {
                                    title: '小程序用户列表',
                                },
                            },
                            {
                                path: '/mb/mbmg/platFormOrder',
                                name: 'mb-mbmg-platFormOrder',
                                component: () =>
                                    import(/* webpackChunkName: "mb-mbmg" */ '@/pages/order/order/platFormOrder.vue'),
                                meta: {
                                    title: '平台订单列表',
                                },
                            },
                            {
                                path: '/mb/mbmg/platFormOrderDetail/:id',
                                name: 'mb-mbmg-platFormOrderDetail',
                                component: orderOrderOrderDetail,
                                meta: {
                                    title: '订单详情',
                                    parentUrl: '/mb/mbmg/platFormOrder',
                                },
                            },
                        ],
                        // 积分管理
                        ...[
                            {
                                path: '/mb/point/pointRule',
                                name: 'mb-point-pointRule',
                                component: () =>
                                    import(/* webpackChunkName: "mb-point" */ '@/pages/mb/point/pointRule.vue'),
                                meta: {
                                    title: '积分场景',
                                },
                            },
                        ],
                        // 成长值管理
                        ...[
                            {
                                path: '/mb/growthValue/growthValueRule',
                                name: 'mb-growthValue-growthValueRule',
                                component: () =>
                                    import(/* webpackChunkName: "mb-growthValue" */ '@/pages/mb/growthValue/growthValueRule.vue'),
                                meta: {
                                    title: '成长值场景',
                                },
                            },
                        ],
                        // 会员认证
                        ...[
                            {
                                path: '/mb/buyerAuth/buyerAuthInfo',
                                name: 'mb-buyerAuth-buyerAuthInfo',
                                component: () =>
                                    import(/* webpackChunkName: "mb-buyerAuth" */ '@/pages/mb/buyerAuth/buyerAuthInfo.vue'),
                                meta: {
                                    title: '会员认证',
                                },
                            },
                            {
                                path: '/mb/buyerAuth/buyerAuthInfo/detail/:id',
                                name: 'mb-buyerAuth-buyerAuthInfoDetail',
                                component: () =>
                                    import(/* webpackChunkName: "mb-buyerAuth" */ '@/pages/mb/buyerAuth/buyerAuthInfoDetail.vue'),
                                meta: {
                                    title: '会员认证详情',
                                    parentUrl: '/mb/buyerAuth/buyerAuthInfo',
                                },
                            },
                        ],
                        // 充值卡管理
                        ...[
                            {
                                path: '/mb/prepaidCard/prepaidCard',
                                name: 'mb-prepaidCard-prepaidCard',
                                component: () =>
                                    import(/* webpackChunkName: "mb-prepaidCard" */ '@/pages/mb/prepaidCard/prepaidCard.vue'),
                                meta: {
                                    title: '充值卡列表',
                                },
                            },
                        ],
                        // 礼品卡管理
                        ...[
                            {
                                path: '/mb/giftCard/giftCard',
                                name: 'mb-giftCard-giftCard',
                                component: () =>
                                    import(/* webpackChunkName: "mb-prepaidCard" */ '@/pages/mb/giftCard/giftCard.vue'),
                                meta: {
                                    title: '礼品卡列表',
                                },
                            },
                        ],
                        // 提现管理
                        ...[
                            {
                                path: '/mb/buyerWithdraw/buyerWithdrawAudit',
                                name: 'mb-buyerWithdraw-buyerWithdrawAudit',
                                component: () =>
                                    import(/* webpackChunkName: "mb-buyerWithdraw" */ '@/pages/mb/buyerWithdraw/buyerWithdrawAudit.vue'),
                                meta: {
                                    title: '会员提现审核',
                                },
                            },
                            {
                                path: '/mb/buyerWithdraw/buyerWithdrawAudit/detail/:id',
                                name: 'mb-buyerWithdraw-buyerWithdrawAuditDetail',
                                component: () =>
                                    import(/* webpackChunkName: "mb-buyerWithdraw" */ '@/pages/mb/buyerWithdraw/buyerWithdrawAuditDetail.vue'),
                                meta: {
                                    title: '会员提现审核',
                                    parentUrl: '/mb/buyerWithdraw/buyerWithdrawAudit',
                                },
                            },
                        ],
                    ],
                    // 分销管理
                    ...[
                        //分销商管理
                        ...[
                            {
                                path: '/dt/dtmg/distributorLevel',
                                name: 'dt-dtmg-distributorLevel',
                                component: () =>
                                    import(/* webpackChunkName: "dt-dtmg" */ '@/pages/dt/dtmg/distributorLevel.vue'),
                                meta: {
                                    title: '分销商等级',
                                },
                            },
                            {
                                path: '/dt/dtmg/distributorLevel/rightsDescEdit/:id?',
                                name: 'dt-dtmg-distributorLevel-rightsDescEdit',
                                component: () =>
                                    import(/* webpackChunkName: "dt-dtmg" */ '@/pages/dt/dtmg/rightsDescEdit.vue'),
                                meta: {
                                    title: '代理装修',
                                    parentUrl: '/dt/dtmg/distributorLevel',
                                },
                            },
                            {
                                path: '/dt/dtmg/distributorLevel/distributeIncomeDescEdit/:id?',
                                name: 'dt-dtmg-distributorLevel-distributeIncomeDescEdit',
                                component: () =>
                                    import(/* webpackChunkName: "dt-dtmg" */ '@/pages/dt/dtmg/distributeIncomeDescEdit.vue'),
                                meta: {
                                    title: '分润装修',
                                    parentUrl: '/dt/dtmg/distributorLevel',
                                },
                            },
                            {
                                path: '/dt/dtmg/distributor',
                                name: 'dt-dtmg-distributor',
                                component: () =>
                                    import(/* webpackChunkName: "dt-dtmg" */ '@/pages/dt/dtmg/distributor.vue'),
                                meta: {
                                    title: '分销商列表',
                                },
                            },
                            {
                                path: '/dt/dtmg/distributor/edit/:id?',
                                name: 'dt-dtmg-distributorEdit',
                                component: () =>
                                    import(/* webpackChunkName: "dt-dtmg" */ '@/pages/dt/dtmg/distributorEdit.vue'),
                                meta: {
                                    title: '分销商详情',
                                    parentUrl: '/dt/dtmg/distributor',
                                },
                            },
                            {
                                path: '/dt/dtmg/distributor/buyerVisitLogDetail/:id?',
                                name: 'dt-dtmg-buyerVisitLogDetail',
                                component: () =>
                                    import(/* webpackChunkName: "dt-dtmg" */ '@/pages/dt/dtmg/buyerVisitLogDetail.vue'),
                                meta: {
                                    title: '流量统计',
                                    parentUrl: '/dt/dtmg/distributor',
                                },
                            },
                            {
                                path: '/dt/dtmg/distributor/subordinate/:id',
                                name: 'dt-dtmg-distributor-subordinate',
                                component: () =>
                                    import(/* webpackChunkName: "dt-dtmg" */ '@/pages/dt/dtmg/distributorSubordinate.vue'),
                                meta: {
                                    title: '分销商列表',
                                    parentUrl: '/dt/dtmg/distributor',
                                },
                            },
                            {
                                path: '/dt/dtmg/distributor/salesman/:id',
                                name: 'dt-dtmg-distributor-salesman',
                                component: () =>
                                    import(/* webpackChunkName: "dt-dtmg" */ '@/pages/dt/dtmg/distributorSalesman.vue'),
                                meta: {
                                    title: '分销商列表',
                                    parentUrl: '/dt/dtmg/distributor',
                                },
                            },
                        ],
                        //分销商统计
                        ...[
                            {
                                path: '/dt/stat/distributorStat',
                                name: 'dt-stat-distributorStat',
                                component: () =>
                                    import(/* webpackChunkName: "dt-stat" */ '@/pages/dt/stat/distributorStat.vue'),
                                meta: {
                                    title: '分销商统计',
                                },
                            },
                            {
                                path: '/dt/stat/distributionVisitLogStat',
                                name: 'dt-stat-distributionVisitLogStat',
                                component: () =>
                                    import(/* webpackChunkName: "dt-stat" */ '@/pages/dt/stat/distributionVisitLogStat.vue'),
                                meta: {
                                    title: '流量统计',
                                },
                            },
                            {
                                path: '/dt/stat/distributionUrllinkTypeStat',
                                name: 'dt-stat-distributionUrllinkTypeStat',
                                component: () =>
                                    import(/* webpackChunkName: "dt-stat" */ '@/pages/dt/stat/distributionUrllinkTypeStat.vue'),
                                meta: {
                                    title: '流量统计详情',
                                    parentUrl: '/dt/stat/distributionVisitLogStat',
                                },
                            },
                            {
                                path: '/dt/stat/distributorReconciliation',
                                name: 'dt-stat-distributorReconciliation',
                                component: () =>
                                    import(/* webpackChunkName: "dt-stat" */ '@/pages/dt/stat/distributorReconciliation.vue'),
                                meta: {
                                    title: '分销商账单',
                                },
                            },
                            {
                                path: '/dt/stat/distributorBill',
                                name: 'dt-stat-distributorBill',
                                component: () =>
                                    import(/* webpackChunkName: "dt-stat" */ '@/pages/dt/stat/distributorBill.vue'),
                                meta: {
                                    title: '分销月账单',
                                },
                            },
                        ],
                        // 提现管理
                        {
                            path: '/dt/withdraw/distributorWithdraw',
                            name: 'dt-withdraw-distributorWithdraw',
                            component: () =>
                                import(/* webpackChunkName: "dt-withdraw" */ '@/pages/dt/withdraw/distributorWithdraw.vue'),
                            meta: {
                                title: '提现管理',
                            },
                        },
                        {
                            path: '/dt/withdraw/distributorWithdraw/detail/:id',
                            name: 'dt-withdraw-distributorWithdrawDetail',
                            component: () =>
                                import(/* webpackChunkName: "dt-withdraw" */ '@/pages/dt/withdraw/distributorWithdrawDetail.vue'),
                            meta: {
                                title: '提现管理',
                                parentUrl: '/dt/withdraw/distributorWithdraw',
                            },
                        },
                    ],
                    // 订单管理
                    ...[
                        // 订单管理
                        ...[
                            {
                                path: '/order/order/order',
                                name: 'order-order-order',
                                component: () =>
                                    import(/* webpackChunkName: "order-order" */ '@/pages/order/order/order.vue'),
                                meta: {
                                    title: '送货上门订单',
                                },
                            },
                            {
                                path: '/order/order/saleOrder',
                                name: 'order-order-saleOrder',
                                component: () =>
                                    import(/* webpackChunkName: "order-order" */ '@/pages/order/order/saleOrder.vue'),
                                meta: {
                                    title: '销售订单',
                                },
                            },
                            {
                                path: '/order/order/orderDetail/:id',
                                name: 'order-order-orderDetail',
                                component: orderOrderOrderDetail,
                                meta: {
                                    title: '订单详情',
                                    parentUrl: '/order/order/order',
                                },
                            },
                            {
                                path: '/order/order/bondedOrder',
                                name: 'order-order-bondedOrder',
                                component: () =>
                                    import(/* webpackChunkName: "order-order" */ '@/pages/order/order/bondedOrder.vue'),
                                meta: {
                                    title: '保税订单',
                                },
                            },
                            {
                                path: '/order/order/bondedOrderDetail/:id',
                                name: 'order-order-bondedOrderDetail',
                                component: orderOrderOrderDetail,
                                meta: {
                                    title: '订单详情',
                                    parentUrl: '/order/order/bondedOrder',
                                },
                            },
                            {
                                path: '/order/order/virtualOrder',
                                name: 'order-order-virtualOrder',
                                component: () =>
                                    import(/* webpackChunkName: "order-order" */ '@/pages/order/order/virtualOrder.vue'),
                                meta: {
                                    title: '虚拟商品订单',
                                },
                            },
                            {
                                path: '/order/order/virtualOrderDetail/:id',
                                name: 'order-order-virtualOrderDetail',
                                component: orderOrderOrderDetail,
                                meta: {
                                    title: '订单详情',
                                    parentUrl: '/order/order/virtualOrder',
                                },
                            },
                            {
                                path: '/order/order/storeOrder',
                                name: 'order-order-storeOrder',
                                component: () =>
                                    import(/* webpackChunkName: "order-order" */ '@/pages/order/order/storeOrder.vue'),
                                meta: {
                                    title: '门店自取订单',
                                },
                            },
                            {
                                path: '/order/order/storeOrderDetail/:id',
                                name: 'order-order-storeOrderDetail',
                                component: orderOrderOrderDetail,
                                meta: {
                                    title: '订单详情',
                                    parentUrl: '/order/order/storeOrder',
                                },
                            },
                            {
                                path: '/order/order/otoldOrder',
                                name: 'order-order-otoldOrder',
                                component: () =>
                                    import(/* webpackChunkName: "order-order" */ '@/pages/order/order/otoldOrder.vue'),
                                meta: {
                                    title: 'O2O配送订单',
                                },
                            },
                            {
                                path: '/order/order/otoldOrderDetail/:id',
                                name: 'order-order-otoldOrderDetail',
                                component: orderOrderOrderDetail,
                                meta: {
                                    title: '订单详情',
                                    parentUrl: '/order/order/otoldOrder',
                                },
                            },
                        ],
                        // 付款单管理
                        ...[
                            {
                                path: '/order/payment/paymentOfflineAudit',
                                name: 'order-payment-paymentOfflineAudit',
                                component: () =>
                                    import(/* webpackChunkName: "order-payment" */ '@/pages/order/payment/paymentOfflineAudit.vue'),
                                meta: {
                                    title: '线下付款审核',
                                },
                            },
                            {
                                path: '/order/payment/paymentOfflineAudit/detail/:id/:source',
                                name: 'order-payment-paymentOfflineAuditDetail',
                                component: () =>
                                    import(/* webpackChunkName: "order-payment" */ '@/pages/order/payment/paymentOfflineAuditDetail.vue'),
                                meta: {
                                    title: '线下付款审核',
                                    parentUrl: '/order/payment/paymentOfflineAudit',
                                },
                            },
                            {
                                path: '/order/payment/customsClearance',
                                name: 'order-payment-customsClearance',
                                component: () =>
                                    import(/* webpackChunkName: "order-payment" */ '@/pages/order/customsClearance/customsClearance.vue'),
                                meta: {
                                    title: '付款单清关报送',
                                },
                            },
                        ],
                        // 订单售后
                        ...[
                            {
                                path: '/order/afterSale/orderRefund',
                                name: 'order-afterSale-orderRefund',
                                component: () =>
                                    import(/* webpackChunkName: "order-afterSale" */ '@/pages/order/afterSale/orderRefund.vue'),
                                meta: {
                                    title: '仅退款',
                                },
                            },
                            {
                                path: '/order/afterSale/orderRefund/detail/:id',
                                name: 'order-afterSale-orderRefundDetail',
                                component: () =>
                                    import(/* webpackChunkName: "order-afterSale" */ '@/pages/order/afterSale/orderRefundDetail.vue'),
                                meta: {
                                    title: '售后详情',
                                    parentUrl: '/order/afterSale/orderRefund',
                                },
                            },
                            {
                                path: '/order/afterSale/orderReturn',
                                name: 'order-afterSale-orderReturn',
                                component: () =>
                                    import(/* webpackChunkName: "order-afterSale" */ '@/pages/order/afterSale/orderReturn.vue'),
                                meta: {
                                    title: '退货退款',
                                },
                            },
                            {
                                path: '/order/afterSale/orderReturn/detail/:id',
                                name: 'order-afterSale-orderReturnDetail',
                                component: () =>
                                    import(/* webpackChunkName: "order-afterSale" */ '@/pages/order/afterSale/orderRefundDetail.vue'),
                                meta: {
                                    title: '售后详情',
                                    parentUrl: '/order/afterSale/orderReturn',
                                },
                            },
                        ],
                        // 商品评价
                        ...[
                            {
                                path: '/order/goodsReview/goodsReview',
                                name: 'order-goodsReview-goodsReview',
                                component: () =>
                                    import(/* webpackChunkName: "order-goodsReview" */ '@/pages/order/goodsReview/goodsReview.vue'),
                                meta: {
                                    title: '商品评价',
                                },
                            },
                            {
                                path: '/order/goodsReview/goodsReview/detail/:id',
                                name: 'order-goodsReview-goodsReviewDetail',
                                component: () =>
                                    import(/* webpackChunkName: "order-goodsReview" */ '@/pages/order/goodsReview/goodsReviewDetail.vue'),
                                meta: {
                                    title: '商品评价详情',
                                    parentUrl: '/order/goodsReview/goodsReview',
                                },
                            },
                        ],
                        // 客服订单
                        ...[
                            {
                                path: '/order/csOrder/csOrder',
                                name: 'order-csOrder-csOrder',
                                component: () =>
                                    import(/* webpackChunkName: "order-csOrder" */ '@/pages/order/csOrder/csOrder.vue'),
                                meta: {
                                    title: '送货上门订单',
                                },
                            },
                        ],
                        // 订单物流
                        ...[
                            {
                                path: '/order/orderTracking/waybillFreightCost',
                                name: 'order-orderTracking-waybillFreightCost',
                                component: () =>
                                    import(/* webpackChunkName: "order-orderTracking" */ '@/pages/order/orderTracking/waybillFreightCost.vue'),
                                meta: {
                                    title: '物流成本',
                                },
                            },
                        ],
                    ],
                    // 运营管理
                    ...[
                        // 平台设置
                        ...[
                            {
                                path: '/om/set/parameterSet',
                                name: 'om-set-parameterSet',
                                component: () =>
                                    import(/* webpackChunkName: "om-set" */ '@/pages/om/set/parameterSet.vue'),
                                meta: {
                                    title: '订单参数设置',
                                },
                            },
                            {
                                path: '/om/set/goodsTypeSet',
                                name: 'om-set-goodsTypeSet',
                                component: () =>
                                    import(/* webpackChunkName: "om-set" */ '@/pages/om/set/goodsTypeSet.vue'),
                                meta: {
                                    title: '商品类型参数设置',
                                },
                            },
                            {
                                path: '/om/set/orderSourceSet',
                                name: 'om-set-orderSourceSet',
                                component: () =>
                                    import(/* webpackChunkName: "om-set" */ '@/pages/om/set/orderSourceSet.vue'),
                                meta: {
                                    title: '订单来源参数设置',
                                },
                            },
                        ],
                        // 店铺装修
                        ...[
                            {
                                path: '/om/decoration/homeDecoration',
                                name: 'om-decoration-homeDecoration',
                                component: () =>
                                    import(/* webpackChunkName: "om-decoration" */ '@/pages/om/decoration/homeDecoration.vue'),
                                meta: {
                                    title: '首页装修',
                                },
                            },
                            {
                                path: '/om/decoration/activityDecoration',
                                name: 'om-decoration-activityDecoration',
                                component: () =>
                                    import(/* webpackChunkName: "om-decoration" */ '@/pages/om/decoration/activityDecoration.vue'),
                                meta: {
                                    title: '活动装修',
                                },
                            },
                            {
                                path: '/om/decoration/adPageDecoration',
                                name: 'om-decoration-adPageDecoration',
                                component: () =>
                                    import(/* webpackChunkName: "om-decoration" */ '@/pages/om/decoration/adPageDecoration.vue'),
                                meta: {
                                    title: '广告位装修',
                                },
                            },
                            // 装修编辑
                            {
                                path: '/om/decoration/home/edit/:id?',
                                name: 'om-decoration-home-edit',
                                component: () =>
                                    import(/* webpackChunkName: "om-decoration" */ '@/pages/om/decoration/edit.vue'),
                                meta: {
                                    title: '首页装修',
                                    parentUrl: '/om/decoration/homeDecoration',
                                },
                            },
                            {
                                path: '/om/decoration/activity/edit/:id?',
                                name: 'om-decoration-activity-edit',
                                component: () =>
                                    import(/* webpackChunkName: "om-decoration" */ '@/pages/om/decoration/edit.vue'),
                                meta: {
                                    title: '活动装修',
                                    parentUrl: '/om/decoration/activityDecoration',
                                },
                            },
                            {
                                path: '/om/decoration/adPage/edit/:id?',
                                name: 'om-decoration-adPage-edit',
                                component: () =>
                                    import(/* webpackChunkName: "om-decoration" */ '@/pages/om/decoration/edit.vue'),
                                meta: {
                                    title: '广告位装修',
                                    parentUrl: '/om/decoration/adPageDecoration',
                                },
                            },
                        ],
                        // 营销活动
                        ...[
                            {
                                path: '/om/coupon/couponTemplet',
                                name: 'om-coupon-couponTemplet',
                                component: () =>
                                    import(/* webpackChunkName: "om-coupon" */ '@/pages/om/coupon/couponTemplet.vue'),
                                meta: {
                                    title: '优惠券模板',
                                },
                            },
                            {
                                path: '/om/coupon/couponGiveProvide',
                                name: 'om-coupon-couponGiveProvide',
                                component: () =>
                                    import(/* webpackChunkName: "om-coupon" */ '@/pages/om/coupon/couponGiveProvide.vue'),
                                meta: {
                                    title: '优惠券发放',
                                },
                            },
                            {
                                path: '/om/coupon/couponGiveProvide/edit/:id?',
                                name: 'om-coupon-couponGiveProvide-edit',
                                component: () =>
                                    import(/* webpackChunkName: "om-coupon" */ '@/pages/om/coupon/couponGiveProvideEdit.vue'),
                                meta: {
                                    title: '优惠券发放',
                                    parentUrl: '/om/coupon/couponGiveProvide',
                                },
                            },
                            {
                                path: '/om/coupon/couponGiveReceive',
                                name: 'om-coupon-couponGiveReceive',
                                component: () =>
                                    import(/* webpackChunkName: "om-coupon" */ '@/pages/om/coupon/couponGiveReceive.vue'),
                                meta: {
                                    title: '优惠券领取',
                                },
                            },
                            {
                                path: '/om/coupon/couponGiveReceive/edit/:id?',
                                name: 'om-coupon-couponGiveReceive-edit',
                                component: () =>
                                    import(/* webpackChunkName: "om-coupon" */ '@/pages/om/coupon/couponGiveReceiveEdit.vue'),
                                meta: {
                                    title: '优惠券领取',
                                    parentUrl: '/om/coupon/couponGiveReceive',
                                },
                            },
                            {
                                path: '/om/coupon/couponGiveConfig',
                                name: 'om-coupon-couponGiveConfig',
                                component: () =>
                                    import(/* webpackChunkName: "om-coupon" */ '@/pages/om/coupon/couponGiveConfig.vue'),
                                meta: {
                                    title: '优惠券场景',
                                },
                            },
                            {
                                path: '/om/coupon/couponActivity',
                                name: 'om-coupon-couponActivity',
                                component: () =>
                                    import(/* webpackChunkName: "om-coupon" */ '@/pages/om/coupon/couponActivity.vue'),
                                meta: {
                                    title: '优惠券活动',
                                },
                            },
                        ],
                        // 抽奖管理
                        ...[
                            {
                                path: '/pm/raffle/raffle',
                                name: 'pm-raffle-raffle',
                                component: () =>
                                    import(/* webpackChunkName: "pm-raffle" */ '@/pages/pm/raffle/raffle.vue'),
                                meta: {
                                    title: '抽奖活动管理',
                                },
                            },
                            {
                                path: '/pm/raffle/raffle/edit/:id?',
                                name: 'pm-raffle-raffle-edit',
                                component: () =>
                                    import(/* webpackChunkName: "pm-raffle" */ '@/pages/pm/raffle/raffleEdit.vue'),
                                meta: {
                                    title: '编辑抽奖活动',
                                    parentUrl: '/pm/raffle/raffle',
                                },
                            },
                            {
                                path: '/pm/raffle/raffle/code/:id?',
                                name: 'pm-raffle-raffle-code',
                                component: () =>
                                    import(/* webpackChunkName: "pm-raffle" */ '@/pages/pm/raffle/raffleCode.vue'),
                                meta: {
                                    title: '抽奖码管理',
                                    parentUrl: '/pm/raffle/raffle',
                                },
                            },
                        ],
                    ],
                    // 统计报表
                    ...[
                        // 商品统计
                        ...[
                            {
                                path: '/stat/goodsStat/goodsSaleStat',
                                name: 'stat-goodsStat-goodsSaleStat',
                                component: () =>
                                    import(/* webpackChunkName: "stat-goodsStat" */ '@/pages/stat/goodsStat/goodsSaleStat.vue'),
                                meta: {
                                    title: '销量统计',
                                },
                            },
                            {
                                path: '/stat/goodsStat/goodsBuyerVisitLog',
                                name: 'stat-goodsStat-goodsBuyerVisitLog',
                                component: () =>
                                    import(/* webpackChunkName: "stat-goodsStat" */ '@/pages/stat/goodsStat/goodsBuyerVisitLog.vue'),
                                meta: {
                                    title: '流量统计',
                                },
                            },
                        ],
                        // 财务统计
                        ...[
                            {
                                path: '/stat/payStat/payStat',
                                name: 'stat-payStat-payStat',
                                component: () =>
                                    import(/* webpackChunkName: "stat-payStat" */ '@/pages/stat/payStat/payStat.vue'),
                                meta: {
                                    title: '销售统计',
                                },
                            },
                            {
                                path: '/stat/payStat/allPayStat',
                                name: 'stat-payStat-allPayStat',
                                component: () =>
                                    import(/* webpackChunkName: "stat-payStat" */ '@/pages/stat/payStat/allPayStat.vue'),
                                meta: {
                                    title: '支付统计',
                                },
                            },
                            {
                                path: '/stat/payStat/paymentCheck',
                                name: 'stat-payStat-paymentCheck',
                                component: () =>
                                    import(/* webpackChunkName: "stat-payStat" */ '@/pages/stat/payStat/paymentCheck.vue'),
                                meta: {
                                    title: '付款单对账',
                                },
                            },
                        ],
                        // 充值卡统计
                        ...[
                            {
                                path: '/stat/prepaidCardStat/prepaidCardStatList',
                                name: 'stat-prepaidCardStat-prepaidCardStatList',
                                component: () =>
                                    import(/* webpackChunkName: "stat-prepaidCardStat" */ '@/pages/stat/prepaidCardStat/prepaidCardStatList.vue'),
                                meta: {
                                    title: '会员充值卡',
                                },
                            },
                            {
                                path: '/stat/prepaidCardStat/buyerPrepaidOrderStatList',
                                name: 'stat-prepaidCardStat-buyerPrepaidOrderStatList',
                                component: () =>
                                    import(/* webpackChunkName: "stat-prepaidCardStat" */ '@/pages/stat/prepaidCardStat/buyerPrepaidOrderStatList.vue'),
                                meta: {
                                    title: '支付统计',
                                },
                            },
                        ],
                        // 分销商统计
                        ...[
                            {
                                path: '/stat/distributorStat/buyerAgentFeeOrderStatList',
                                name: 'stat-distributorStat-buyerAgentFeeOrderStatList',
                                component: () =>
                                    import(/* webpackChunkName: "stat-distributorStat" */ '@/pages/stat/distributorStat/buyerAgentFeeOrderStatList.vue'),
                                meta: {
                                    title: '支付统计',
                                },
                            },
                            {
                                path: '/stat/distributorStat/distributorWithdrawStatList',
                                name: 'stat-distributorStat-distributorWithdrawStatList',
                                component: () =>
                                    import(/* webpackChunkName: "stat-distributorStat" */ '@/pages/stat/distributorStat/distributorWithdrawStatList.vue'),
                                meta: {
                                    title: '提现统计',
                                },
                            },
                            {
                                path: '/stat/distributorStat/distributorStoreStat',
                                name: 'stat-distributorStat-distributorStoreStat',
                                component: () =>
                                    import(/* webpackChunkName: "stat-distributorStat" */ '@/pages/stat/distributorStat/distributorStoreStat.vue'),
                                meta: {
                                    title: '店铺统计',
                                },
                            },
                        ],
                        // 会员统计
                        ...[
                            {
                                path: '/stat/buyerStat/buyerOrderStat',
                                name: 'stat-buyerStat-buyerOrderStat',
                                component: () =>
                                    import(/* webpackChunkName: "stat-buyerStat" */ '@/pages/stat/buyerStat/buyerOrderStat.vue'),
                                meta: {
                                    title: '订单统计',
                                },
                            },
                        ],
                        // 门店统计
                        ...[
                            {
                                path: '/stat/storeStat/buyerOpenStoreOrderStatList',
                                name: 'stat-storeStat-buyerOpenStoreOrderStatList',
                                component: () =>
                                    import(/* webpackChunkName: "stat-storeStat" */ '@/pages/stat/storeStat/buyerOpenStoreOrderStatList.vue'),
                                meta: {
                                    title: '支付统计',
                                },
                            },
                        ],
                        // 订单统计
                        ...[
                            {
                                path: '/stat/orderStat/orderStat',
                                name: 'stat-orderStat-orderStat',
                                component: () =>
                                    import(/* webpackChunkName: "stat-orderStat" */ '@/pages/stat/orderStat/orderStat.vue'),
                                meta: {
                                    title: '订单统计',
                                },
                            },
                            {
                                path: '/stat/orderStat/groupOrderStat',
                                name: 'stat-orderStat-groupOrderStat',
                                component: () =>
                                    import(/* webpackChunkName: "stat-orderStat" */ '@/pages/stat/orderStat/groupOrderStat.vue'),
                                meta: {
                                    title: '团队绩效统计',
                                },
                            },
                        ],
                        // 配送统计
                        ...[
                            {
                                path: '/stat/driverStat/driverStat',
                                name: 'stat-driverStat-driverStat',
                                component: () =>
                                    import(/* webpackChunkName: "stat-orderStat" */ '@/pages/stat/driverStat/driverStat.vue'),
                                meta: {
                                    title: '配送统计',
                                },
                            },
                        ],
                    ],
                    // 定制管理
                    ...[
                        // 商品管理
                        ...[
                            {
                                path: '/cgd/goods/customGoods',
                                name: 'cgd-goods-customGoods',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/goods.vue'),
                                meta: {
                                    title: '商品管理',
                                },
                            },
                            {
                                path: '/cgd/goods/addCustomGoods/:goodsId?',
                                name: 'cgd-goods-addCustomGoods',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/addGoods.vue'),
                                meta: {
                                    title: '添加商品',
                                    parentUrl: '/cgd/goods/customGoods',
                                },
                            },
                        ],
                        // 订单管理
                        ...[
                            {
                                path: '/order/order/customOrder',
                                name: 'order-order-customOrder',
                                component: () =>
                                    import(/* webpackChunkName: "order-order" */ '@/pages/order/order/order.vue'),
                                meta: {
                                    title: '定制商品订单',
                                },
                            },
                            {
                                path: '/order/order/customOrderDetail/:id',
                                name: 'order-order-customOrderDetail',
                                component: orderOrderOrderDetail,
                                meta: {
                                    title: '订单详情',
                                    parentUrl: '/order/order/customOrder',
                                },
                            },
                        ],
                    ],
                    // 直播管理
                    ...[
                        // 直播管理
                        ...[
                            {
                                path: '/pm/live/liveRoom',
                                name: 'pm-live-liveRoom',
                                component: () =>
                                    import(/* webpackChunkName: "pm-live" */ '@/pages/pm/live/liveRoom.vue'),
                                meta: {
                                    title: '直播间管理',
                                },
                            },
                            {
                                path: '/pm/live/liveRoom/goods/:id',
                                name: 'pm-live-liveRoomGoods',
                                component: () =>
                                    import(/* webpackChunkName: "pm-live" */ '@/pages/pm/live/liveRoomGoods.vue'),
                                meta: {
                                    title: '直播间管理',
                                    parentUrl: '/pm/live/liveRoom',
                                },
                            },
                            {
                                path: '/pm/live/liveGoods',
                                name: 'pm-live-liveGoods',
                                component: () =>
                                    import(/* webpackChunkName: "pm-live" */ '@/pages/pm/live/liveGoods.vue'),
                                meta: {
                                    title: '商品管理',
                                },
                            },
                        ],
                    ],
                    // 账单管理
                    ...[
                        // 客户账单
                        ...[
                            {
                                path: '/mb/buyerBill/buyerBillOrderWaitCreate',
                                name: 'mb-buyerBill-buyerBillOrderWaitCreate',
                                component: () =>
                                    import(/* webpackChunkName: "mb-live" */ '@/pages/mb/buyerBill/buyerBillOrderWaitCreate.vue'),
                                meta: {
                                    title: '待生成客户账单',
                                },
                            },
                            {
                                path: '/mb/buyerBill/buyerBillOrderWaitCreateDetail/:billType/:buyerId',
                                name: 'mb-buyerBill-buyerBillOrderWaitCreateDetail',
                                component: () =>
                                    import(/* webpackChunkName: "mb-live" */ '@/pages/mb/buyerBill/buyerBillList.vue'),
                                meta: {
                                    title: '待生成客户账单',
                                    parentUrl: '/mb/buyerBill/buyerBillOrderWaitCreate',
                                },
                            },
                            {
                                path: '/mb/buyerBill/buyerBillOrder',
                                name: 'mb-buyerBill-buyerBillOrder',
                                component: () =>
                                    import(/* webpackChunkName: "mb-live" */ '@/pages/mb/buyerBill/buyerBillOrder.vue'),
                                meta: {
                                    title: '客户账单管理',
                                },
                            },
                            {
                                path: '/mb/buyerBill/buyerBillOrderDetail/:id',
                                name: 'mb-buyerBill-buyerBillOrderDetail',
                                component: () =>
                                    import(/* webpackChunkName: "mb-live" */ '@/pages/mb/buyerBill/buyerBillDetail.vue'),
                                meta: {
                                    title: '客户账单管理',
                                    parentUrl: '/mb/buyerBill/buyerBillOrder',
                                },
                            },
                            {
                                path: '/mb/buyerBill/buyerBillDistributionWaitCreate',
                                name: 'mb-buyerBill-buyerBillDistributionWaitCreate',
                                component: () =>
                                    import(/* webpackChunkName: "mb-live" */ '@/pages/mb/buyerBill/buyerBillDistributionWaitCreate.vue'),
                                meta: {
                                    title: '待生成分销商账单',
                                },
                            },
                            {
                                path: '/mb/buyerBill/buyerBillDistributionWaitCreateDetail/:billType/:buyerId',
                                name: 'mb-buyerBill-buyerBillDistributionWaitCreateDetail',
                                component: () =>
                                    import(/* webpackChunkName: "mb-live" */ '@/pages/mb/buyerBill/buyerBillList.vue'),
                                meta: {
                                    title: '待生成分销商账单',
                                    parentUrl: '/mb/buyerBill/buyerBillDistributionWaitCreate',
                                },
                            },
                            {
                                path: '/mb/buyerBill/buyerBillDistribution',
                                name: 'mb-buyerBill-buyerBillDistribution',
                                component: () =>
                                    import(/* webpackChunkName: "mb-live" */ '@/pages/mb/buyerBill/buyerBillDistribution.vue'),
                                meta: {
                                    title: '分销商账单管理',
                                },
                            },
                            {
                                path: '/mb/buyerBill/buyerBillDistributionDetail/:id',
                                name: 'mb-buyerBill-buyerBillDistributionDetail',
                                component: () =>
                                    import(/* webpackChunkName: "mb-live" */ '@/pages/mb/buyerBill/buyerBillDetail.vue'),
                                meta: {
                                    title: '分销商账单管理',
                                    parentUrl: '/mb/buyerBill/buyerBillDistribution',
                                },
                            },
                        ],
                    ],
                    // 溯源管理
                    ...[
                        // 产品管理
                        ...[
                            {
                                path: '/ts/marketCode/marketCodeProduct',
                                name: 'ts-marketCode-marketCodeProduct',
                                component: () =>
                                    import(/* webpackChunkName: "ts-marketCode" */ '@/pages/ts/marketCode/marketCodeProduct.vue'),
                                meta: {
                                    title: '产品管理',
                                },
                            },
                            {
                                path: '/ts/marketCode/marketCodeProductDetail/:id',
                                name: 'ts-marketCode-marketCodeProductDetail',
                                component: () =>
                                    import(/* webpackChunkName: "ts-live" */ '@/pages/ts/marketCode/marketCodeProductDetail.vue'),
                                meta: {
                                    title: '产品管理',
                                    parentUrl: '/ts/marketCode/marketCodeProduct',
                                },
                            },
                        ],
                        // 一物一码管理
                        ...[
                            {
                                path: '/ts/marketCode/marketCodeBatch',
                                name: 'ts-marketCode-marketCodeBatch',
                                component: () =>
                                    import(/* webpackChunkName: "ts-marketCode" */ '@/pages/ts/marketCode/marketCodeBatch.vue'),
                                meta: {
                                    title: '一物一码批次管理',
                                },
                            },
                            {
                                path: '/ts/marketCode/marketCode',
                                name: 'ts-marketCode-marketCode',
                                component: () =>
                                    import(/* webpackChunkName: "ts-marketCode" */ '@/pages/ts/marketCode/marketCode.vue'),
                                meta: {
                                    title: '一物一码管理',
                                },
                            },
                            {
                                path: '/ts/marketCode/marketCodeSource/:id',
                                name: 'ts-marketCode-marketCodeSource',
                                component: () =>
                                    import(/* webpackChunkName: "ts-live" */ '@/pages/ts/marketCode/marketCodeSource.vue'),
                                meta: {
                                    title: '一物一码溯源管理',
                                    parentUrl: '/ts/marketCode/marketCode',
                                },
                            },
                            {
                                path: '/ts/marketCode/marketCodeCat',
                                name: 'ts-marketCode-marketCodeCat',
                                component: () =>
                                    import(/* webpackChunkName: "ts-catmg" */ '@/pages/ts/marketCode/marketCodeCat.vue'),
                                meta: {
                                    title: '一物一码分类管理',
                                },
                            },
                        ],
                    ],
                ],
                // ---门店路由
                ...[
                    // 门店管理
                    ...[
                        // 门店管理
                        ...[
                            {
                                path: '/store/storemg/shopStoreInfo',
                                name: 'store-storemg-shopStoreInfo',
                                component: shopStoreStoremgShopStoreEdit,
                                meta: {
                                    title: '门店信息',
                                },
                            },
                            {
                                path: '/store/storemg/shopStoreServiceTime',
                                name: 'store-storemg-shopStoreServiceTime',
                                component: shopStoreStoremgShopStoreServiceTime,
                                meta: {
                                    title: '服务时间设置',
                                },
                            },
                            {
                                path: '/store/storemg/shopStoreFreight',
                                name: 'store-storemg-shopStoreFreight',
                                component: shopStoreStoremgShopStoreFreight,
                                meta: {
                                    title: '运费设置',
                                },
                            },
                        ],
                        // 门店商品
                        ...[
                            {
                                path: '/store/storeGoods/shopStoreGoods',
                                name: 'store-storeGoods-shopStoreGoods',
                                component: () =>
                                    import(/* webpackChunkName: "store-storeGoods" */ '@/pages/store/storeGoods/shopStoreGoods.vue'),
                                meta: {
                                    title: '商品列表',
                                },
                            },
                            {
                                path: '/store/storeGoods/shopStoreGoods/edit/:id?',
                                name: 'store-storeGoods-shopStoreGoodsEdit',
                                component: () =>
                                    import(/* webpackChunkName: "store-storeGoods" */ '@/pages/store/storeGoods/shopStoreGoodsEdit.vue'),
                                meta: {
                                    title: '商品列表',
                                    parentUrl: '/store/storeGoods/shopStoreGoods',
                                },
                            },
                            {
                                path: '/store/storeGoods/shopStoreGoodsCat',
                                name: 'store-storeGoods-shopStoreGoodsCat',
                                component: () =>
                                    import(/* webpackChunkName: "store-storeGoods" */ '@/pages/store/storeGoods/shopStoreGoodsCat.vue'),
                                meta: {
                                    title: '类目列表',
                                },
                            },
                        ],
                    ],
                    // 订单管理
                    ...[
                        // 订单管理
                        ...[
                            {
                                path: '/storeOrder/order/order',
                                name: 'storeOrder-order-order',
                                component: () =>
                                    import(/* webpackChunkName: "storeOrder-order" */ '@/pages/storeOrder/order/order.vue'),
                                meta: {
                                    title: '送货上门订单',
                                },
                            },
                            {
                                path: '/storeOrder/order/orderDetail/:id',
                                name: 'storeOrder-order-orderDetail',
                                component: orderOrderOrderDetail,
                                meta: {
                                    title: '订单详情',
                                    parentUrl: '/storeOrder/order/order',
                                },
                            },
                            {
                                path: '/storeOrder/order/storeOrder',
                                name: 'storeOrder-order-storeOrder',
                                component: () =>
                                    import(/* webpackChunkName: "storeOrder-order" */ '@/pages/storeOrder/order/storeOrder.vue'),
                                meta: {
                                    title: '门店自取订单',
                                },
                            },
                            {
                                path: '/storeOrder/order/storeOrderDetail/:id',
                                name: 'storeOrder-order-storeOrderDetail',
                                component: orderOrderOrderDetail,
                                meta: {
                                    title: '订单详情',
                                    parentUrl: '/storeOrder/order/storeOrder',
                                },
                            },
                            {
                                path: '/storeOrder/order/csStoreOrder',
                                name: 'storeOrder-order-csStoreOrder',
                                component: () =>
                                    import(/* webpackChunkName: "storeOrder-order" */ '@/pages/storeOrder/order/csStoreOrder.vue'),
                                meta: {
                                    title: '扫码消单',
                                },
                            },
                            {
                                path: '/storeOrder/order/csStoreOrderDetail/:id',
                                name: 'storeOrder-order-csStoreOrderDetail',
                                component: orderOrderOrderDetail,
                                meta: {
                                    title: '订单详情',
                                    parentUrl: '/storeOrder/order/csStoreOrder',
                                },
                            },
                        ],
                        // 订单售后
                        ...[
                            {
                                path: '/storeOrder/afterSale/orderRefund',
                                name: 'storeOrder-afterSale-orderRefund',
                                component: () =>
                                    import(/* webpackChunkName: "storeOrder-afterSale" */ '@/pages/order/afterSale/orderRefund.vue'),
                                meta: {
                                    title: '仅退款',
                                },
                            },
                            {
                                path: '/storeOrder/afterSale/orderRefund/detail/:id',
                                name: 'storeOrder-afterSale-orderRefundDetail',
                                component: () =>
                                    import(/* webpackChunkName: "storeOrder-afterSale" */ '@/pages/order/afterSale/orderRefundDetail.vue'),
                                meta: {
                                    title: '售后详情',
                                    parentUrl: '/storeOrder/afterSale/orderRefund',
                                },
                            },
                            {
                                path: '/storeOrder/afterSale/orderReturn',
                                name: 'storeOrder-afterSale-orderReturn',
                                component: () =>
                                    import(/* webpackChunkName: "storeOrder-afterSale" */ '@/pages/order/afterSale/orderReturn.vue'),
                                meta: {
                                    title: '退货退款',
                                },
                            },
                            {
                                path: '/storeOrder/afterSale/orderReturn/detail/:id',
                                name: 'storeOrder-afterSale-orderReturnDetail',
                                component: () =>
                                    import(/* webpackChunkName: "storeOrder-afterSale" */ '@/pages/order/afterSale/orderRefundDetail.vue'),
                                meta: {
                                    title: '售后详情',
                                    parentUrl: '/storeOrder/afterSale/orderReturn',
                                },
                            },
                        ],
                    ],
                ],
            ],
        },
    ],
    async () => {
        console.log('初始路由数据开始', 111111);
        const loadingInstance = Loading.service({
            lock: true,
            text: '正在加载所需数据,请稍后...',
        });
        const jsons = await Promise.all([Api.Rs.User.menu(), Api.Rs.User.menuResource()]).finally(() => {
            loadingInstance.close();
        });
        MenuProps.data = jsons[0].data.data;
        addPower(jsons[1].data.data);
    },
);

// export default router;
