/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class ShopGoodsStyle extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shopGoodsStyle';
    }

    data({ styleName, currentPage, pageSize } = {}) {
        return super.get('/data', {
            styleName,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, styleName, orderNum, isAvailable } = {}) {
        return super.post('/save', {
            id,
            styleName,
            orderNum,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}

export default new ShopGoodsStyle();
export { ShopGoodsStyle };
