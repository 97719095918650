/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class GiftCard extends Mb {
    constructor() {
        super();
        this.baseUrl += '/giftCard';
    }

    data({ cardName, cardSn, mobile, remarks, currentPage, pageSize } = {}) {
        return super.get('/data', {
            cardName,
            cardSn,
            mobile,
            remarks,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, cardName, cardSn, totalAmount, remarks, quantity } = {}) {
        return super.post('/save', {
            id,
            cardName,
            cardSn,
            totalAmount,
            remarks,
            quantity,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}

export default new GiftCard();
export { GiftCard };
