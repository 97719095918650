/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class WaybillFreightCost extends Ex {
    constructor() {
        super();
        this.baseUrl += '/waybillFreightCost';
    }

    data({ orderSn, trackingNumber, currentPage, pageSize } = {}) {
        return super.get('/data', {
            orderSn,
            trackingNumber,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, packageWeight, freightAmount, taxAmount, materialAmount, otherAmount } = {}) {
        return super.post('/save', {
            id,
            packageWeight,
            freightAmount,
            taxAmount,
            materialAmount,
            otherAmount,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new WaybillFreightCost();
export { WaybillFreightCost };
