import '@babel/polyfill';
import Vue from 'vue';
import './router';
import './store';
import Components from '@vmf/components';
import LComponents from './components';
import api from './api/install';

Vue.use(LComponents);
Vue.use(Components);
Vue.use(api);
