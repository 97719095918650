/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import Order from './Order';
import ParameterSet from './ParameterSet';
import OrderSourceSet from './OrderSourceSet';
import Payment from './Payment';
import OrderRefund from './OrderRefund';
import GoodsReview from './GoodsReview';
import PaymentCheck from './PaymentCheck';
import WaybillFreightCost from './WaybillFreightCost';
import CustomsClearance from './CustomsClearance';

export default {
    Order,
    ParameterSet,
    OrderSourceSet,
    Payment,
    OrderRefund,
    GoodsReview,
    PaymentCheck,
    WaybillFreightCost,
    CustomsClearance,
};
