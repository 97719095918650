/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import Activity from './Activity';
import CouponTemplet from './CouponTemplet';
import CouponGive from './CouponGive';
import CouponGiveConfig from './CouponGiveConfig';
import CouponActivity from './CouponActivity';
import FulfilGive from './FulfilGive';
import LiveRoom from './LiveRoom';
import LiveGoods from './LiveGoods';
import Raffle from './Raffle';
import RaffleDetail from './RaffleDetail';

export default {
    Activity,
    CouponTemplet,
    CouponGive,
    CouponGiveConfig,
    CouponActivity,
    FulfilGive,
    LiveRoom,
    LiveGoods,
    Raffle,
    RaffleDetail,
};
