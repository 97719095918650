/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class ShopDriver extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shopDriver';
    }

    data({ driverName, driverMobile, driverStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            driverName,
            driverMobile,
            driverStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, driverName, driverMobile, password, regionId, detailAddress, driverStatus, remarks } = {}) {
        return super.post('/save', {
            id,
            driverName,
            driverMobile,
            password,
            regionId,
            detailAddress,
            driverStatus,
            remarks,
        });
    }
}

export default new ShopDriver();
export { ShopDriver };
