/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class DistributorLevel extends Mb {
    constructor() {
        super();
        this.baseUrl += '/distributorLevel';
    }

    data({ levelName, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            levelName,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const fileBasePath = json.data.data.fileBasePath;
            const levelIcon = json.data.data.levelIcon;
            if (!levelIcon) {
                json.data.data.levelIcon = [];
            } else {
                json.data.data.levelIcon = levelIcon.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const levelCard = json.data.data.levelCard;
            if (!levelCard) {
                json.data.data.levelCard = [];
            } else {
                json.data.data.levelCard = levelCard.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({
        id,
        levelName,
        levelDesc,
        levelIcon,
        levelCard,
        orderNum,
        isAvailable,
        isDefault,
        isOpenApply,
        distributionIncomeMode,
        agentFee,
        recommendIncomeLevel,
        recommendIncome,
        bestowDistributor,
        shopStoreType,
        freightRate,
        openStoreFee,
        openStoreBalance,
        isGainGrowthValue,
        isGrowthValueUpgrade,
        gradeGrowthValue,
        isDisableDistributionIncome,
        isGstFree,
    } = {}) {
        let levelIconStr = undefined;
        if (levelIcon) {
            levelIconStr = levelIcon
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        let levelCardStr = undefined;
        if (levelCard) {
            levelCardStr = levelCard
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        return super.post('/save', {
            id,
            levelName,
            levelDesc,
            levelIcon: levelIconStr,
            levelCard: levelCardStr,
            orderNum,
            isAvailable,
            isDefault,
            isOpenApply,
            distributionIncomeMode,
            agentFee,
            recommendIncomeLevel,
            recommendIncome,
            bestowDistributor,
            shopStoreType,
            freightRate,
            openStoreFee,
            openStoreBalance,
            isGainGrowthValue,
            isGrowthValueUpgrade,
            gradeGrowthValue,
            isDisableDistributionIncome,
            isGstFree,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable }) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    isDefault({ id, isDefault }) {
        return super.post('/isDefault', {
            id,
            isDefault,
        });
    }

    select() {
        return super.get('/select', {});
    }

    editDistributorLevel({ id, rightsDesc, distributeIncomeDesc }) {
        return super.post('/editDistributorLevel', { id, rightsDesc, distributeIncomeDesc });
    }
}

export default new DistributorLevel();
export { DistributorLevel };
