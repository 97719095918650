/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Pm } from '../pm/Pm';

class FulfilGive extends Pm {
    constructor() {
        super();
        this.baseUrl += '/fulfilGive';
    }

    data({ fulfilGiveType, activityName, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            fulfilGiveType,
            activityName,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            let fulfilGive = json.data.data.fulfilGive;
            fulfilGive.activityTime = [new Date(fulfilGive.activityBeginTime), new Date(fulfilGive.activityEndTime)];
            return json;
        });
    }

    save({
        id,
        fulfilGiveType,
        activityName,
        activityTime,
        activityDesc,
        isLimitGoods,
        isAvailable,
        freeFreightJson,
        reduceAmountConfig,
        goodsListJson,
    } = {}) {
        let activityBeginTime = null;
        let activityEndTime = null;
        if (activityTime.length === 2) {
            activityBeginTime = activityTime[0];
            activityEndTime = activityTime[1];
        }
        return super.post('/save', {
            id,
            fulfilGiveType,
            activityName,
            activityBeginTime,
            activityEndTime,
            activityDesc,
            isLimitGoods,
            isAvailable,
            freeFreightJson,
            reduceAmountConfig,
            goodsListJson,
        });
    }
}

export default new FulfilGive();
export { FulfilGive };
