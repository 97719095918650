/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Stat } from './Stat';

class OrderStat extends Stat {
    constructor() {
        super();
        this.baseUrl += '/orderStat';
    }

    orderStatList({ orderSn, statDateDay, currentPage, pageSize } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            statBeginDate = statDateDay[0];
            statEndDate = statDateDay[1];
        }
        return super.get('/orderStatList', {
            orderSn,
            statBeginDate,
            statEndDate,
            currentPage,
            pageSize,
        });
    }

    groupOrderStatList({ distributorName, referenceId, statDateDay, currentPage, pageSize } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            statBeginDate = statDateDay[0];
            statEndDate = statDateDay[1];
        }
        return super.get('/groupOrderStatList', {
            distributorName,
            referenceId,
            statBeginDate,
            statEndDate,
            currentPage,
            pageSize,
        });
    }
}

export default new OrderStat();
export { OrderStat };
