/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';

class GoodsForbidSell extends Gd {
    constructor() {
        super();
        this.baseUrl += '/goodsForbidSell';
    }

    data({ goodsId, buyerId, goodsName, goodsSn, currentPage, pageSize } = {}) {
        return super.get('/data', {
            goodsId,
            buyerId,
            goodsName,
            goodsSn,
            currentPage,
            pageSize,
        });
    }

    save({ goodsIds, buyerIds } = {}) {
        return super.post('/save', {
            goodsIds,
            buyerIds,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new GoodsForbidSell();
export { GoodsForbidSell };
