/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class ShopPremiumTemplate extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shopPremiumTemplate';
    }

    data({ templateName, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            templateName,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, templateName, premiumAmount, templateDesc, isAvailable } = {}) {
        return super.post('/save', {
            id,
            templateName,
            premiumAmount,
            templateDesc,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    select() {
        return super.get('/select', {});
    }
}

export default new ShopPremiumTemplate();
export { ShopPremiumTemplate };
