/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class StoreInitGoodsCat extends Sp {
    constructor() {
        super();
        this.baseUrl += '/storeInitGoodsCat';
    }

    data({ goodsCatName, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            goodsCatName,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, goodsCatName, isAvailable, orderNum } = {}) {
        return super.post('/save', {
            id,
            goodsCatName,
            isAvailable,
            orderNum,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new StoreInitGoodsCat();
export { StoreInitGoodsCat };
