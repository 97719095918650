/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Rs } from './Rs';

class OrgCountryCode extends Rs {
    constructor() {
        super();
        this.baseUrl += '/orgCountryCode';
    }

    data({ orgId }) {
        return super.get('/data', { orgId });
    }

    save({ orgId, jsonStr } = {}) {
        return super.post('/save', {
            orgId,
            jsonStr,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    changeOrgCountryCodeOrderNum({ id, orderNum }) {
        return super.post('/changeOrgCountryCodeOrderNum', {
            id,
            orderNum,
        });
    }
}

export default new OrgCountryCode();
export { OrgCountryCode };
