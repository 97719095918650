/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class PaymentCheck extends Ex {
    constructor() {
        super();
        this.baseUrl += '/paymentCheck';
    }

    data({ paymentType, statDateDay, paymentCheckStatus, currentPage, pageSize } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            statBeginDate = statDateDay[0];
            statEndDate = statDateDay[1];
        }
        let paymentTypeStr = null;
        if (Array.isArray(paymentType)) {
            paymentTypeStr = paymentType.join(',');
        }
        return super.get('/data', {
            paymentType: paymentTypeStr,
            statBeginDate,
            statEndDate,
            paymentCheckStatus,
            currentPage,
            pageSize,
        });
    }
}

export default new PaymentCheck();
export { PaymentCheck };
