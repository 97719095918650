/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class Distributor extends Mb {
    constructor() {
        super();
        this.baseUrl += '/distributor';
    }

    data({
        distributorName,
        distributorTel,
        distributorLevelId,
        isJoinDistributor,
        referenceId,
        isAddWechat,
        isSalesman,
        referenceName,
        joinTime,
        isDistributor,
        approvalStatus,
        currentPage,
        pageSize,
    } = {}) {
        let joinBeginTime = null;
        let joinEndTime = null;
        if (joinTime != null && joinTime.length === 2) {
            joinBeginTime = joinTime[0];
            joinEndTime = joinTime[1];
        }
        return super.get('/data', {
            distributorName,
            distributorTel,
            distributorLevelId,
            isJoinDistributor,
            referenceId,
            isAddWechat,
            isSalesman,
            referenceName,
            joinBeginTime,
            joinEndTime,
            isDistributor,
            approvalStatus,
            currentPage,
            pageSize,
        });
    }

    getDistributorDetail({ id }) {
        return super.get('/getDistributorDetail', {
            id,
        });
    }

    cancelDistributor({ id }) {
        return super.post('/cancelDistributor', {
            id,
        });
    }

    saveDistributor({
        id,
        distributorLevelId,
        distributorName,
        wechatId,
        distributorEmail,
        dueTime,
        referenceId,
        isDistributionIncome,
        isStoreAutoDistributionIncome,
        openStoreFee,
        openStoreBalance,
    }) {
        return super.post('/saveDistributor', {
            id,
            distributorLevelId,
            distributorName,
            wechatId,
            distributorEmail,
            dueTime,
            referenceId,
            isDistributionIncome,
            isStoreAutoDistributionIncome,
            openStoreFee,
            openStoreBalance,
        });
    }

    approvalDistributor({
        id,
        distributorLevelId,
        distributorName,
        wechatId,
        distributorEmail,
        approvalStatus,
        approvalRemark,
        dueTime,
        referenceId,
        isDistributionIncome,
        isStoreAutoDistributionIncome,
        openStoreFee,
        openStoreBalance,
    }) {
        return super.post('/approvalDistributor', {
            id,
            distributorLevelId,
            distributorName,
            wechatId,
            distributorEmail,
            approvalStatus,
            approvalRemark,
            dueTime,
            referenceId,
            isDistributionIncome,
            isStoreAutoDistributionIncome,
            openStoreFee,
            openStoreBalance,
        });
    }

    getDistributorSubordinateList({ id }) {
        return super.get('/getDistributorSubordinateList', { id });
    }

    changeIsAddWechat({ id, isAddWechat }) {
        return super.post('/changeIsAddWechat', { id, isAddWechat });
    }

    changeIsSalesman({ id, isSalesman }) {
        return super.post('/changeIsSalesman', { id, isSalesman });
    }

    batchSetSalesman({ ids, salesmanBuyerId }) {
        return super.post('/batchSetSalesman', { ids, salesmanBuyerId });
    }

    salesmanStat({ id }) {
        return super.get('/salesmanStat', { id });
    }
}

export default new Distributor();
export { Distributor };
