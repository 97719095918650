import { storeRegisterModule } from 'vmf';

storeRegisterModule('a', {
    state: {
        b: 1,
    },
    getters: {},
    mutations: {},
    actions: {},
});
