/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class BuyerWithdraw extends Mb {
    constructor() {
        super();
        this.baseUrl += '/buyerWithdraw';
    }

    data({ withdrawApplySn, mobile, withdrawType, withdrawStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            withdrawApplySn,
            mobile,
            withdrawType,
            withdrawStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super.get('/get', { id });
    }

    audit({ id, auditStatus, auditRemarks } = {}) {
        return super.post('/audit', {
            id,
            auditStatus,
            auditRemarks,
        });
    }
}

export default new BuyerWithdraw();
export { BuyerWithdraw };
