/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';

class Goods extends Gd {
    constructor() {
        super();
        this.baseUrl += '/goods';
    }

    data({
        goodsName,
        goodsSn,
        goodsCatId,
        supplierId,
        goodsStatusType,
        shopGoodsCatId,
        storeInitGoodsCatId,
        isPrivateCustom,
        goodsSendModeList,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            goodsName,
            goodsSn,
            goodsCatId,
            supplierId,
            goodsStatusType,
            shopGoodsCatId,
            storeInitGoodsCatId,
            isPrivateCustom,
            goodsSendModeList,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/getDetail', { id });
    }

    getGoodsDetailVo({ id }) {
        return super.get('/getGoodsDetailVo', { id });
    }

    save(data) {
        return super.postJson('/save', data);
    }

    saveAndPublish(data) {
        return super.postJson('/saveAndPublish', data);
    }

    onSaleGoods({ goodsId, orderDetailListJson }) {
        return super.post('/onSaleGoods', { goodsId, orderDetailListJson });
    }

    offSaleGoods({ goodsId }) {
        return super.post('/offSaleGoods', { goodsId });
    }

    /**
     * 获取商品(当选择/装修时用)
     * @param ids 有值不分页
     * @param orderByKey
     * @param orderByType
     *
     * @param goodsName
     * @param goodsSn
     * @param goodsStatus
     * @param isShopStoreGoods
     * @param isActivityGoods
     * @param activityId
     * @param currentPage
     * @param pageSize
     * @returns {AxiosPromise<any>}
     */
    selectByPage({
        ids,
        orderByKey,
        orderByType,
        goodsName,
        goodsSn,
        goodsStatus,
        isShopStoreGoods,
        isActivityGoods,
        activityId,
        goodsForbidSellBuyerIdNE,
        shopGoodsCatIdNE,
        storeInitGoodsCatIdNE,
        currentPage,
        pageSize,
    } = {}) {
        return super.get(
            '/selectByPage',
            {
                ids,
                orderByKey,
                orderByType,
                goodsName,
                goodsSn,
                goodsStatus,
                isShopStoreGoods,
                isActivityGoods,
                activityId,
                goodsForbidSellBuyerIdNE,
                shopGoodsCatIdNE,
                storeInitGoodsCatIdNE,
                currentPage,
                pageSize,
            },
            {
                isRepeat: true,
            },
        );
    }

    changeOrderNum({ id, orderNum }) {
        return super.post('/changeOrderNum', { id, orderNum });
    }

    changeBaseSales({ id, baseSales }) {
        return super.post('/changeBaseSales', { id, baseSales });
    }

    batchSetBaseSales(data) {
        return super.post('/batchSetBaseSales', data);
    }

    editGoods({ id, goodsShareCopy, goodsMultiStyleJson }) {
        return super.post('/editGoods', { id, goodsShareCopy, goodsMultiStyleJson });
    }

    saveThirdPlatformSkuSn({ id, thirdPlatformSkuSn }) {
        return super.post('/saveThirdPlatformInfo', { id, thirdPlatformSkuSn });
    }

    saveThirdPlatformSkuQuantity({ id, thirdPlatformSkuQuantity }) {
        return super.post('/saveThirdPlatformInfo', { id, thirdPlatformSkuQuantity });
    }

    saveGoodsShopGoodsCat({ goodsIds, shopGoodsCatId }) {
        return super.post('/saveGoodsShopGoodsCat', { goodsIds, shopGoodsCatId });
    }

    delGoodsShopGoodsCat({ goodsIds, shopGoodsCatId }) {
        return super.post('/delGoodsShopGoodsCat', { goodsIds, shopGoodsCatId });
    }

    saveGoodsStoreInitGoodsCat({ goodsIds, storeInitGoodsCatId }) {
        return super.post('/saveGoodsStoreInitGoodsCat', { goodsIds, storeInitGoodsCatId });
    }

    delGoodsStoreInitGoodsCat({ goodsIds, storeInitGoodsCatId }) {
        return super.post('/delGoodsStoreInitGoodsCat', { goodsIds, storeInitGoodsCatId });
    }

    findSkuMapList({ goodsName, goodsSn, specDesc, goodsStatusType, currentPage, pageSize } = {}) {
        return super.get(
            '/findSkuMapList',
            {
                goodsName,
                goodsSn,
                specDesc,
                goodsStatusType,
                currentPage,
                pageSize,
            },
            {
                isRepeat: true,
            },
        );
    }
}

export default new Goods();
export { Goods };
