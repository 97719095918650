/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import { HttpBase } from '@/api/base/HttpBase';

class Ts extends HttpBase {
    constructor() {
        super();
        this.baseUrl += '/ts';
    }
}

export default new Ts();
export { Ts };
