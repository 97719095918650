/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';

class GoodsMultiStyle extends Gd {
    constructor() {
        super();
        this.baseUrl += '/goodsMultiStyle';
    }

    initGoodsMultiStyle({ goodsId }) {
        return super.get('/initGoodsMultiStyle', { goodsId });
    }
}

export default new GoodsMultiStyle();
export { GoodsMultiStyle };
