/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';

class SkuMemberPrice extends Gd {
    constructor() {
        super();
        this.baseUrl += '/skuMemberPrice';
    }

    getSkuMemberPriceList({ goodsId }) {
        return super.get('/getSkuMemberPriceList', { goodsId });
    }

    saveSkuMemberPrice({ jsonStr }) {
        return super.post('/saveSkuMemberPrice', { jsonStr });
    }

    skuMemberPriceList({
        goodsName,
        goodsSn,
        skuSn,
        goodsStatusType,
        thirdPlatformSkuSnNull,
        supplierId,
        goodsSendModeList,
        currentPage,
        pageSize,
    }) {
        return super.get('/skuMemberPriceList', {
            goodsName,
            goodsSn,
            skuSn,
            goodsStatusType,
            thirdPlatformSkuSnNull,
            supplierId,
            goodsSendModeList,
            currentPage,
            pageSize,
        });
    }

    saveSkuMemberPriceItem({ skuId, buyerLevelId, price }) {
        return super.post(
            '/saveSkuMemberPriceItem',
            {
                skuId,
                buyerLevelId,
                price,
            },
            {
                isRepeat: true,
            },
        );
    }
}

export default new SkuMemberPrice();
export { SkuMemberPrice };
