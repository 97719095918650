/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import MarketCodeProduct from './MarketCodeProduct';
import MarketCode from './MarketCode';
import MarketCodeCat from './MarketCodeCat';
import MarketCodeBatch from './MarketCodeBatch';

export default {
    MarketCodeProduct,
    MarketCode,
    MarketCodeCat,
    MarketCodeBatch,
};
