/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';

class SkuFreightCost extends Gd {
    constructor() {
        super();
        this.baseUrl += '/skuFreightCost';
    }

    skuCostList({ goodsName, goodsSn, skuSn, supplierName, goodsStatusType, currentPage, pageSize } = {}) {
        return super.get('/skuCostList', {
            goodsName,
            goodsSn,
            skuSn,
            supplierName,
            goodsStatusType,
            currentPage,
            pageSize,
        });
    }

    getSkuCostDetail({ id }) {
        return super.get('/getSkuCostDetail', { id });
    }

    save({ ids, costPrice, skuFreightCostJson }) {
        return super.post('/save', { ids, costPrice, skuFreightCostJson });
    }

    getSkuPriceConfig() {
        return super.get('/getSkuPriceConfig');
    }

    saveSkuPriceConfig({ exchangeRate, proxyPriceRatio, retailPriceRatio, exchangeRate1, retailPriceRatio1 }) {
        return super.post('/saveSkuPriceConfig', {
            exchangeRate,
            proxyPriceRatio,
            retailPriceRatio,
            exchangeRate1,
            retailPriceRatio1,
        });
    }

    saveProxyPrice({ id, proxyPrice }) {
        return super.post('/saveProxyPrice', { id, proxyPrice });
    }

    saveRetailPrice({ id, retailPrice }) {
        return super.post('/saveRetailPrice', { id, retailPrice });
    }

    saveRetailPriceRedis({ id, retailPrice1 }) {
        return super.post('/saveRetailPriceRedis', { id, retailPrice1 });
    }
}

export default new SkuFreightCost();
export { SkuFreightCost };
