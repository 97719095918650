/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class DistributorWithdraw extends Mb {
    constructor() {
        super();
        this.baseUrl += '/distributorWithdraw';
    }

    data({ mobile, distributorName, withdrawStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            mobile,
            distributorName,
            withdrawStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    payDistributorWithdraw({ id, paymentAmount, paymentVoucher, remarks }) {
        const paymentVoucherStr = paymentVoucher
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/payDistributorWithdraw', {
            id,
            paymentAmount,
            paymentVoucher: paymentVoucherStr,
            remarks,
        });
    }

    cancelDistributorWithdraw({ id, remarks }) {
        return super.post('/cancelDistributorWithdraw', {
            id,
            remarks,
        });
    }
}

export default new DistributorWithdraw();
export { DistributorWithdraw };
