/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import CmsCatalog from './CmsCatalog';
import CmsArticle from './CmsArticle';

export default {
    CmsCatalog,
    CmsArticle,
};
