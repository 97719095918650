/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class PrepaidCard extends Mb {
    constructor() {
        super();
        this.baseUrl += '/prepaidCard';
    }

    data({ cardName, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            cardName,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const cardLogo = json.data.data.prepaidCard.cardLogo;
            const fileBasePath = json.data.data.fileBasePath;
            if (!cardLogo) {
                json.data.data.prepaidCard.cardLogo = [];
            } else {
                json.data.data.prepaidCard.cardLogo = cardLogo.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({ id, cardName, cardValue, cardLogo, cardDesc, isAvailable, priceListJson } = {}) {
        const cardLogoStr = cardLogo
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            cardName,
            cardValue,
            cardLogo: cardLogoStr,
            cardDesc,
            isAvailable,
            priceListJson,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable }) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}

export default new PrepaidCard();
export { PrepaidCard };
