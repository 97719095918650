
<template>
    <el-popover
        class="x-qr-code"
        placement="bottom"
        trigger="hover"
        @show="onPopoverShow"
    >
        <div
            v-if="qrcodeSrc"
            class="x-qr-code-img"
        >
            <img
                :src="qrcodeSrc"
                alt=""
            >
        </div>
        <div
            class="out-show"
            slot="reference"
        >
            {{ text }}
        </div>
    </el-popover>
</template>
<script >
export default {
    name: 'QrCode',
    data() {
        return {
            qrcodeSrc: '',
        };
    },
    props: {
        text: {
            type: String,
            default: '',
        },
        url: {
            type: String,
            default: '',
        },
    },
    methods: {
        async createQrcode() {
            const { url } = this;
            if (!url) {
                this.qrcodeSrc = '';
                return;
            }
            this.qrcodeSrc = await this.$utils.QRCode.toDataURL(url);
        },
        onPopoverShow() {
            this.createQrcode();
        },
    },
};
</script>
<style scoped lang="scss">
.x-qr-code {
    > .out-show {
        cursor: pointer;
    }
}
.x-qr-code-img {
    width: 200px;
    height: 200px;
    > img {
        display: block;
        width: 100%;
        height: 100%;
    }
}
</style>
