/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class ShopAftersaleTemplate extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shopAftersaleTemplate';
    }

    data({ templateName, currentPage, pageSize }) {
        return super.get('/data', { templateName, currentPage, pageSize });
    }

    saveJsonData({ id, templateDesc, templateName, isAvailable, isDefault } = {}) {
        return super.post('/saveJsonData', {
            id,
            templateDesc,
            templateName,
            isAvailable,
            isDefault,
        });
    }

    getJsonData({ id } = {}) {
        return super.get('/getJsonData', {
            id,
        });
    }

    delete({ id } = {}) {
        return super.post('/delete', {
            id,
        });
    }

    status({ id, isAvailable }) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    isDefault({ id, isDefault }) {
        return super.post('/isDefault', {
            id,
            isDefault,
        });
    }

    select() {
        return super.get('/select', {});
    }
}

export default new ShopAftersaleTemplate();
export { ShopAftersaleTemplate };
