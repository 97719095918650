/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Stat } from './Stat';

class PrepaidCardStat extends Stat {
    constructor() {
        super();
        this.baseUrl += '/prepaidCardStat';
    }

    prepaidCardStatList({ nickName, mobile, buyerStatus, buyerLevelId, currentPage, pageSize } = {}) {
        return super.get('/prepaidCardStatList', {
            nickName,
            mobile,
            buyerStatus,
            buyerLevelId,
            currentPage,
            pageSize,
        });
    }

    buyerPrepaidOrderStatList({ buyerId, statDateDay, paymentType, currentPage, pageSize } = {}) {
        let statBeginDate = null;
        let statEndDate = null;
        if (statDateDay != null && statDateDay.length === 2) {
            statBeginDate = statDateDay[0];
            statEndDate = statDateDay[1];
        }
        return super.get('/buyerPrepaidOrderStatList', {
            buyerId,
            statBeginDate,
            statEndDate,
            paymentType,
            currentPage,
            pageSize,
        });
    }
}

export default new PrepaidCardStat();
export { PrepaidCardStat };
