/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sp } from './Sp';

class Shop extends Sp {
    constructor() {
        super();
        this.baseUrl += '/shop';
    }

    data({ shopName, shopStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            shopName,
            shopStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const fileBasePath = json.data.data.fileBasePath;
            const shopLogo = json.data.data.shopLogo;
            if (!shopLogo) {
                json.data.data.shopLogo = [];
            } else {
                json.data.data.shopLogo = shopLogo.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (json.data.data.otoldWaybillTemplate) {
                const miniAppsImg = json.data.data.otoldWaybillTemplate.miniAppsImg;
                if (!miniAppsImg) {
                    json.data.data.miniAppsImg = [];
                } else {
                    json.data.data.miniAppsImg = miniAppsImg.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                }
                const customerServiceImg = json.data.data.otoldWaybillTemplate.customerServiceImg;
                if (!customerServiceImg) {
                    json.data.data.customerServiceImg = [];
                } else {
                    json.data.data.customerServiceImg = customerServiceImg.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                }
                const waybillTemplate = json.data.data.otoldWaybillTemplate.waybillTemplate;
                if (!waybillTemplate) {
                    json.data.data.waybillTemplate = [];
                } else {
                    json.data.data.waybillTemplate = waybillTemplate.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                }
            }
            const shopNoticeImage = json.data.data.shopNoticeImage;
            if (!shopNoticeImage) {
                json.data.data.shopNoticeImage = [];
            } else {
                json.data.data.shopNoticeImage = shopNoticeImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (json.data.data.otherConfig) {
                const loadingImg = json.data.data.otherConfig.loadingImg;
                if (!loadingImg) {
                    json.data.data.loadingImg = [];
                } else {
                    json.data.data.loadingImg = loadingImg.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                }
                const wxaccountQrCodeImage = json.data.data.otherConfig.wxaccountQrCodeImage;
                if (!wxaccountQrCodeImage) {
                    json.data.data.wxaccountQrCodeImage = [];
                } else {
                    json.data.data.wxaccountQrCodeImage = wxaccountQrCodeImage.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                }
                json.data.data.currencySymbol = json.data.data.otherConfig.currencySymbol;
                json.data.data.currencyCode = json.data.data.otherConfig.currencyCode;
                json.data.data.themeColorMain = json.data.data.otherConfig.themeColorMain;
                json.data.data.themeColorSecond = json.data.data.otherConfig.themeColorSecond;
                json.data.data.isQuickBuyerAddress = json.data.data.otherConfig.isQuickBuyerAddress;
                json.data.data.moduleConfigCart = json.data.data.otherConfig.moduleConfigCart;
                json.data.data.meiQiaEntId = json.data.data.otherConfig.meiQiaEntId;
                json.data.data.isPushOrderErp = json.data.data.otherConfig.isPushOrderErp;
                json.data.data.erpAppKey = json.data.data.otherConfig.erpAppKey;
                json.data.data.erpAppSecret = json.data.data.otherConfig.erpAppSecret;
                json.data.data.isPushOrderToGateway = json.data.data.otherConfig.isPushOrderToGateway;
                json.data.data.gatewayAppKey = json.data.data.otherConfig.gatewayAppKey;
                json.data.data.gatewayAppSecret = json.data.data.otherConfig.gatewayAppSecret;
                json.data.data.gatewayPlatformName = json.data.data.otherConfig.gatewayPlatformName;
                json.data.data.gatewayPlatformCode = json.data.data.otherConfig.gatewayPlatformCode;
                json.data.data.gatewayDistributorName = json.data.data.otherConfig.gatewayDistributorName;
                json.data.data.gatewayEbpCode = json.data.data.otherConfig.gatewayEbpCode;
                json.data.data.gatewayRecpAccount = json.data.data.otherConfig.gatewayRecpAccount;
                json.data.data.gatewayRecpCode = json.data.data.otherConfig.gatewayRecpCode;
                json.data.data.gatewayRecpName = json.data.data.otherConfig.gatewayRecpName;
                json.data.data.isShowRMBAmount = json.data.data.otherConfig.isShowRMBAmount;
                json.data.data.RMBAmountRate = json.data.data.otherConfig.RMBAmountRate;
                json.data.data.sendConsignor = json.data.data.otherConfig.sendConsignor;
                json.data.data.sendTel = json.data.data.otherConfig.sendTel;
                json.data.data.isStoreEditSendInfo = json.data.data.otherConfig.isStoreEditSendInfo;
                json.data.data.qskdAppKey = json.data.data.otherConfig.qskdAppKey;
                json.data.data.qskdAppSecret = json.data.data.otherConfig.qskdAppSecret;
                json.data.data.amountFormat = json.data.data.otherConfig.amountFormat;
                json.data.data.isOrderEditSendInfo = json.data.data.otherConfig.isOrderEditSendInfo;
                json.data.data.minWithdrawAmount = json.data.data.otherConfig.minWithdrawAmount;
                json.data.data.withdrawPoundageRate = json.data.data.otherConfig.withdrawPoundageRate;
                json.data.data.goodsSendMode = json.data.data.otherConfig.goodsSendMode
                    ? json.data.data.otherConfig.goodsSendMode.split(',')
                    : [];
                json.data.data.goodsConfig = json.data.data.otherConfig.goodsConfig;
                json.data.data.buyerConfig = json.data.data.otherConfig.buyerConfig;
                json.data.data.marketCodeContacts = json.data.data.otherConfig.marketCodeContacts;
                json.data.data.marketCodeCouponId = json.data.data.otherConfig.marketCodeCouponId;
                json.data.data.marketCodeWarnNum = json.data.data.otherConfig.marketCodeWarnNum;
                json.data.data.isGiftCardReturnPurse = json.data.data.otherConfig.isGiftCardReturnPurse;
                json.data.data.isRegisterIHealth = json.data.data.otherConfig.isRegisterIHealth;
            }
            return json;
        });
    }

    save({
        id,
        orgId,
        shopName,
        shopLogo,
        miniAppsImg,
        customerServiceImg,
        wxaccountQrCodeImage,
        waybillTemplate,
        principalName,
        principalPosition,
        principalMobile,
        principalEmail,
        principalQq,
        shopRegionId,
        shopAddress,
        shopDesc,
        freightMode,
        isAllowMoreLevel,
        shopDomain,
        shopLevelId,
        shopStatus,
        joinTime,
        memberRegisterMode,
        memberAccountSign,
        wxaccountType,
        appid,
        secret,
        token,
        aeskey,
        maAppid,
        maSecret,
        maToken,
        maAeskey,
        mapPlaceKey,
        publicApikey,
        publicSecret,
        remarks,
        distributionIncomeMode,
        distributorRegisterMode,
        isMustInvitationCode,
        distributionLevel,
        sellGoodsType,
        shipMethod,
        paymentMode,
        onlinePaymentType,
        prepaidPaymentType,
        storePaymentType,
        orderNoticeMode,
        orderNoticeMobile,
        orderNoticeWebchat,
        isAllowOrder,
        isShowNotice,
        shopNoticeImage,
        shopNoticeLink,
        loadingImg,
        currencySymbol,
        currencyCode,
        themeColorMain,
        themeColorSecond,
        isQuickBuyerAddress,
        moduleConfigCart,
        isGoodsMultiStyle,
        shopStoreType,
        meiQiaEntId,
        isPushOrderErp,
        erpAppKey,
        erpAppSecret,
        isPushOrderToGateway,
        gatewayAppKey,
        gatewayAppSecret,
        gatewayPlatformName,
        gatewayPlatformCode,
        gatewayDistributorName,
        gatewayEbpCode,
        gatewayRecpAccount,
        gatewayRecpCode,
        gatewayRecpName,
        isShowRMBAmount,
        RMBAmountRate,
        sendConsignor,
        sendTel,
        isStoreEditSendInfo,
        storeOpenMode,
        qskdAppKey,
        qskdAppSecret,
        amountFormat,
        prepaidPaymentMode,
        distributorQrcodeExpire,
        isDistributorQrcodeUnique,
        isOrderEditSendInfo,
        minWithdrawAmount,
        withdrawPoundageRate,
        goodsSendMode,
        goodsConfig,
        buyerConfig,
        marketCodeContacts,
        marketCodeCouponId,
        marketCodeWarnNum,
        isGiftCardReturnPurse,
        isRegisterIHealth,
    } = {}) {
        const shopLogoStr = shopLogo
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const otoldWaybillTemplate = {
            miniAppsImg: miniAppsImg
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
            customerServiceImg: customerServiceImg
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
            waybillTemplate: waybillTemplate
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
        };
        const shopNotice = {
            shopNoticeImage: shopNoticeImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
            shopNoticeLink: shopNoticeLink,
        };
        const otherConfig = {
            loadingImg: loadingImg
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
            wxaccountQrCodeImage: wxaccountQrCodeImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
            currencySymbol: currencySymbol,
            currencyCode: currencyCode,
            themeColorMain: themeColorMain,
            themeColorSecond: themeColorSecond,
            isQuickBuyerAddress: isQuickBuyerAddress,
            moduleConfigCart: moduleConfigCart,
            meiQiaEntId: meiQiaEntId,
            isPushOrderErp: isPushOrderErp,
            erpAppKey: erpAppKey,
            erpAppSecret: erpAppSecret,
            isPushOrderToGateway: isPushOrderToGateway,
            gatewayAppKey: gatewayAppKey,
            gatewayAppSecret: gatewayAppSecret,
            gatewayPlatformName: gatewayPlatformName,
            gatewayPlatformCode: gatewayPlatformCode,
            gatewayDistributorName: gatewayDistributorName,
            gatewayEbpCode: gatewayEbpCode,
            gatewayRecpAccount: gatewayRecpAccount,
            gatewayRecpCode: gatewayRecpCode,
            gatewayRecpName: gatewayRecpName,
            isShowRMBAmount: isShowRMBAmount,
            RMBAmountRate: RMBAmountRate,
            sendConsignor: sendConsignor,
            sendTel: sendTel,
            isStoreEditSendInfo: isStoreEditSendInfo,
            qskdAppKey: qskdAppKey,
            qskdAppSecret: qskdAppSecret,
            amountFormat: amountFormat,
            isOrderEditSendInfo: isOrderEditSendInfo,
            minWithdrawAmount: minWithdrawAmount,
            withdrawPoundageRate: withdrawPoundageRate,
            goodsSendMode: goodsSendMode.join(','),
            goodsConfig: goodsConfig,
            buyerConfig: buyerConfig,
            marketCodeContacts: marketCodeContacts,
            marketCodeCouponId: marketCodeCouponId,
            marketCodeWarnNum: marketCodeWarnNum,
            isGiftCardReturnPurse: isGiftCardReturnPurse,
            isRegisterIHealth: isRegisterIHealth,
        };
        return super.post('/save', {
            id,
            orgId,
            shopName,
            shopLogo: shopLogoStr,
            principalName,
            principalPosition,
            principalMobile,
            principalEmail,
            principalQq,
            shopRegionId,
            shopAddress,
            shopDesc,
            freightMode,
            isAllowMoreLevel,
            shopDomain,
            shopLevelId,
            shopStatus,
            joinTime,
            memberRegisterMode,
            memberAccountSign,
            wxaccountType,
            appid,
            secret,
            token,
            aeskey,
            maAppid,
            maSecret,
            maToken,
            maAeskey,
            mapPlaceKey,
            publicApikey,
            publicSecret,
            remarks,
            distributionIncomeMode,
            distributorRegisterMode,
            isMustInvitationCode,
            distributionLevel,
            sellGoodsType,
            shipMethod,
            paymentMode,
            onlinePaymentType,
            prepaidPaymentType,
            storePaymentType,
            orderNoticeMode,
            orderNoticeMobile,
            orderNoticeWebchat,
            isAllowOrder,
            isShowNotice,
            shopNotice: JSON.stringify(shopNotice),
            otherConfig: JSON.stringify(otherConfig),
            otoldWaybillTemplate: JSON.stringify(otoldWaybillTemplate),
            isGoodsMultiStyle,
            shopStoreType,
            storeOpenMode,
            prepaidPaymentMode,
            distributorQrcodeExpire,
            isDistributorQrcodeUnique,
        });
    }

    getShopInfo() {
        return super.get('/getShopInfo', {}).then(json => {
            const shopLogo = json.data.data.shopLogo;
            const fileBasePath = json.data.data.fileBasePath;
            if (!shopLogo) {
                json.data.data.shopLogo = [];
            } else {
                json.data.data.shopLogo = shopLogo.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }

            const shopNoticeImage = json.data.data.shopNoticeImage;
            if (!shopNoticeImage) {
                json.data.data.shopNoticeImage = [];
            } else {
                json.data.data.shopNoticeImage = shopNoticeImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (json.data.data.otherConfig) {
                json.data.data.isShowRMBAmount = json.data.data.otherConfig.isShowRMBAmount;
                json.data.data.RMBAmountRate = json.data.data.otherConfig.RMBAmountRate;
                json.data.data.sendConsignor = json.data.data.otherConfig.sendConsignor;
                json.data.data.sendTel = json.data.data.otherConfig.sendTel;
                json.data.data.isStoreEditSendInfo = json.data.data.otherConfig.isStoreEditSendInfo;
                json.data.data.minWithdrawAmount = json.data.data.otherConfig.minWithdrawAmount;
                json.data.data.withdrawPoundageRate = json.data.data.otherConfig.withdrawPoundageRate;
                json.data.data.goodsSendMode = json.data.data.otherConfig.goodsSendMode
                    ? json.data.data.otherConfig.goodsSendMode.split(',')
                    : [];
                json.data.data.marketCodeContacts = json.data.data.otherConfig.marketCodeContacts;
                json.data.data.marketCodeCouponId = json.data.data.otherConfig.marketCodeCouponId;
                json.data.data.marketCodeWarnNum = json.data.data.otherConfig.marketCodeWarnNum;
            }
            return json;
        });
    }

    saveShopInfo({
        shopName,
        shopLogo,
        principalName,
        principalPosition,
        principalMobile,
        principalEmail,
        principalQq,
        shopRegionId,
        shopAddress,
        shopDesc,
        freightMode,
        isAllowMoreLevel,
        isAllowOrder,
        isShowNotice,
        shopNoticeImage,
        shopNoticeLink,
        isShowRMBAmount,
        RMBAmountRate,
        sendConsignor,
        sendTel,
        isStoreEditSendInfo,
        minWithdrawAmount,
        withdrawPoundageRate,
        marketCodeContacts,
        marketCodeCouponId,
        marketCodeWarnNum,
    } = {}) {
        const shopLogoStr = shopLogo.reduce((prev, curr) => (prev.push(curr.name), prev), []).join(',');
        const shopNotice = {
            shopNoticeImage: shopNoticeImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(','),
            shopNoticeLink: shopNoticeLink,
        };
        const otherConfig = {
            isShowRMBAmount: isShowRMBAmount,
            RMBAmountRate: RMBAmountRate,
            sendConsignor: sendConsignor,
            sendTel: sendTel,
            isStoreEditSendInfo: isStoreEditSendInfo,
            minWithdrawAmount: minWithdrawAmount,
            withdrawPoundageRate: withdrawPoundageRate,
            marketCodeContacts: marketCodeContacts,
            marketCodeCouponId: marketCodeCouponId,
            marketCodeWarnNum: marketCodeWarnNum,
        };
        return super.post('/saveShopInfo', {
            shopName,
            shopLogo: shopLogoStr,
            principalName,
            principalPosition,
            principalMobile,
            principalEmail,
            principalQq,
            shopRegionId,
            shopAddress,
            shopDesc,
            freightMode,
            isAllowMoreLevel,
            isAllowOrder,
            isShowNotice,
            shopNotice: JSON.stringify(shopNotice),
            otherConfig: JSON.stringify(otherConfig),
        });
    }

    getShopAvailableGoodsType() {
        return super.get('/getShopAvailableGoodsType', {});
    }

    getShopAvailableDistributionIncomeMode() {
        return super.get('/getShopAvailableDistributionIncomeMode', {});
    }

    getShopAvailablePaymentMode() {
        return super.get('/getShopAvailablePaymentMode', {});
    }

    getShopPaymentTypeList() {
        return super.get('/getShopPaymentTypeList', {});
    }

    getShopOnlinePaymentTypeList() {
        return super.get('/getShopOnlinePaymentTypeList', {});
    }

    getShopPrepaidPaymentTypeList() {
        return super.get('/getShopPrepaidPaymentTypeList', {});
    }

    getShopAvailableStoreType() {
        return super.get('/getShopAvailableStoreType', {});
    }

    getShopShipMethodList() {
        return super.get('/getShopShipMethodList', {});
    }

    getShopGoodsSendModeList() {
        return super.get('/getShopGoodsSendModeList', {});
    }
}

export default new Shop();
export { Shop };
