/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ts } from './Ts';

class MarketCode extends Ts {
    constructor() {
        super();
        this.baseUrl += '/marketCode';
    }

    data({
        activityName,
        productBrand,
        productTitle,
        productCode,
        marketCodeCatId,
        marketCodeBatchInfo,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            activityName,
            productBrand,
            productTitle,
            productCode,
            marketCodeCatId,
            marketCodeBatchInfo,
            currentPage,
            pageSize,
        });
    }

    createMarketCode({
        id,
        marketCodeProductId,
        activityName,
        applyNum,
        remarks,
        isCopy,
        marketCodeCatId,
        codeSnPrefix,
        codeSnBegin,
        marketCodeBatchId,
        batchBeginNum,
    } = {}) {
        return super.post(
            '/createMarketCode',
            {
                id,
                marketCodeProductId,
                activityName,
                applyNum,
                remarks,
                isCopy,
                marketCodeCatId,
                codeSnPrefix,
                codeSnBegin,
                marketCodeBatchId,
                batchBeginNum,
            },
            { timeout: 0, isHandleError: true },
        );
    }

    status({ id, isAvailable }) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    saveSource({ id, detailJson }) {
        return super.post('/saveSource', { id, detailJson });
    }

    getSource({ id }) {
        return super.get('/getSource', { id });
    }

    delete({ ids } = {}) {
        return super.post(
            '/delete',
            {
                ids,
            },
            { timeout: 0, isHandleError: true },
        );
    }
}

export default new MarketCode();
export { MarketCode };
