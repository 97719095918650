/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Rs } from './Rs';

class CountryCode extends Rs {
    constructor() {
        super();
        this.baseUrl += '/countryCode';
    }

    data({ regionName, regionNameEn, regionCode, countryCode, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            regionName,
            regionNameEn,
            regionCode,
            countryCode,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, regionName, regionNameEn, regionCode, countryCode, isAvailable } = {}) {
        return super.post('/save', {
            id,
            regionName,
            regionNameEn,
            regionCode,
            countryCode,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}

export default new CountryCode();
export { CountryCode };
